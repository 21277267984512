import React, { useState, useRef, Fragment } from "react"
import { useField } from "react-final-form"
import TextareaAutosize from "react-autosize-textarea"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import ReactTooltip from "react-tooltip"
import _ from "lodash"
import { randomString } from "../../util"
import * as Styles from "./TextFieldStyles"

const TextField = ({
  label,
  description,
  tooltipText,
  tooltipOptions,
  className,
  useInput,
  onChange,
  borderStyle,
  maxLength,
  hideLengthCounter = false,
  offsetLabel = false,
  disabled,
  JFStyle = false,
  isHidden,
  useIdentityFunctionParser = false,
  ...props
}) => {
  const { input, meta } = useField(props.name,
    useIdentityFunctionParser ? {
      parse: value => value
    } : {}
  )
  const longErrorTooltip = useRef(null)
  const [randomId] = useState(randomString(10))
  const [focused, setFocused] = useState(false)
  const focusedRef = useRef(focused)
  focusedRef.current = focused

  const hasError = (meta.error || meta.submitError) && meta.touched
  const hasLongError = (meta.error && meta.error.length >= 40) && meta.touched
  const hasLabel = !!label
  const hasLengthCounter = _.isNumber(maxLength) && !hideLengthCounter

  const showLongError = ({
    longError,
    auto,
    tooltip,
    show,
  }) => {
    if (longError) {
      if (auto) {
        ReactTooltip.show(tooltip.current)
        setTimeout(() => {
          if (!focusedRef.current) {
            ReactTooltip.hide(tooltip.current)
          }
        }, 5000)
      } else {
        if (!show) {
          ReactTooltip.hide(tooltip.current)
        }
        ReactTooltip.show(tooltip.current)
      }
    }
  }

  const onChangeValue = (value) => {
    if (props.onChangeDataRefValue) {
      props.onChangeDataRefValue(value)
    } else {
      input.onChange(value)
    }
  }

  return (
    <Styles.Container
      className={className}
      focused={focused || (meta.touched && meta.active)}
      disabled={disabled}
      hasError={hasError}
      hasLongError={hasLongError}
      hasLabel={hasLabel}
      hasLengthCounter={hasLengthCounter}
      borderStyle={borderStyle}
      offsetLabel={offsetLabel}
      JFStyle={JFStyle}
      isHidden={isHidden}
    >
      <Styles.LabelContainer 
        flexEnd={!label && !tooltipText && !hasLongError}
        focused={focused || (meta.touched && meta.active)}
        hasError={hasError}
        disabled={disabled}
      >
        {/* <RenderCount /> */}
        {label ?
          <Styles.Label
            className="label"
            focused={focused || (meta.touched && meta.active)}
            hasError={hasError}
            disabled={disabled}
          >
            <span>{label}</span>
            {tooltipText ?
              <Fragment>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="tooltip-icon text-field-tooltip-icon"
                  data-tip={tooltipText}
                  data-for={randomId}
                />
                <Styles.StyledTooltip
                  id={randomId}
                  effect="solid"
                  multiline
                  {...tooltipOptions}
                />
              </Fragment>
              :
              null
            }
            {hasLongError && hasError ?
              <Fragment>
                <Styles.LongErrorIconWrapper
                  show={hasLongError && hasError}
                  ref={longErrorTooltip}
                  data-tip={meta.error}
                  data-for={`${randomId}-error`}
                >
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="tooltip-icon text-field-tooltip-icon"
                  />
                </Styles.LongErrorIconWrapper>
                <Styles.StyledTooltip
                  id={`${randomId}-error`}
                  effect="solid"
                  type="error"
                  multiline
                  {...tooltipOptions}
                />
              </Fragment>
              :
              null
            }
          </Styles.Label>
          :
          null
        }
        {_.isNumber(maxLength) && !hideLengthCounter ?
          <span className="length-count">{_.get(input.value, "length", 0)}/{maxLength}</span>
          :
          null
        }
      </Styles.LabelContainer>
      {description && <div style={{ paddingBottom: "4px", fontSize: "0", lineHeight: "paragraph", color: "grays.gray5", whiteSpace: "pre-wrap" }}>{description}</div>}
      <div className="content">
        {useInput ?
          <input
            {...input}
            {...props}
            className={`${disabled && props.type === "number" ? "disabled-input" : null}`}
            onChange={(e) => {
              if (disabled) {
                return
              }
              let { value } = e.target
              if (_.isNumber(maxLength)) {
                value = value.slice(0, maxLength)
              }
              if (props.type === "number") {
                value = parseFloat(value)
              }
              onChangeValue(value)
            }}
            onFocus={() => {
              // console.log("textfield onfocus")
              if (props.onFocus) props.onFocus()
              input.onFocus()
              setFocused(true)
              showLongError({
                longError: hasLongError,
                tooltip: longErrorTooltip,
                show: true
              })
            }}
            onBlur={() => {
              if (!disabled) {
                if (props.onBlur) props.onBlur()
                input.onBlur()
                setFocused(false)
                setTimeout(() => {
                  showLongError({
                    longError: hasLongError,
                    tooltip: longErrorTooltip,
                    auto: true
                  })
                }, 0)
              }
            }}
          />
          :
          <TextareaAutosize
            {...input}
            {...props}
            onChange={(e) => {
              if (disabled) {
                return
              }
              let { value } = e.target
              if (_.isNumber(maxLength)) {
                value = value.slice(0, maxLength)
              }
              if (props.type === "number") {
                value = parseFloat(value)
              }
              onChangeValue(value)
            }}
            onFocus={() => {
              // console.log("textfield onfocus")
              if (props.onFocus) props.onFocus()
              input.onFocus()
              setFocused(true)
              showLongError({
                longError: hasLongError,
                tooltip: longErrorTooltip,
                show: true
              })
            }}
            onBlur={() => {
              if (!disabled) {
                if (props.onBlur) props.onBlur()
                input.onBlur()
                setFocused(false)
                setTimeout(() => {
                  showLongError({
                    longError: hasLongError,
                    tooltip: longErrorTooltip,
                    auto: true
                  })
                }, 0)
              }
            }}
          />
        }
      </div>
      <Styles.BottomRow
        hasLabel={hasLabel}
        offsetLabel={offsetLabel}
      >
        {(hasError && !hasLongError) ?
          <Styles.Error
            className="error-container"
            data-cy="form-text-field_error"
          >
            {meta.error || meta.submitError}
          </Styles.Error>
          :
          <div></div>
        }
      </Styles.BottomRow>
    </Styles.Container>
  )
}

export default TextField
