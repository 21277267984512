import React, { Fragment } from "react"
import _ from "lodash"
import styled from "styled-components"

export const TextField = styled.input`
  font-size: 1em;
  color: #626262;
  outline: none;
  margin-bottom: 3px;
  margin-top: 2px;
  /* margin-left: 4px; */
  margin-right: 4px;
  padding: 6px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  overflow: hidden;
  overflow-wrap: break-word;
  width: 100%;
  box-sizing: border-box;
`

const WhatsappMessageTemplateSampleButtonContent = ({
  index,
  values,
  selectedMT,
  ...props
}) => {
  const urlButtonIndex = selectedMT.buttons.findIndex(b => b.type === "URL")

  const sampleValue = _.get(selectedMT, `buttons[${urlButtonIndex}].example[0]`, "")
  const previewText = _.get(selectedMT, `buttons[${urlButtonIndex}].url`, "")

  return (
    <Fragment>
      <TextField
        label={previewText}
        value={sampleValue}
        onChange={(e) => {
          const clonedSampleContent = _.cloneDeep(selectedMT)
          _.set(clonedSampleContent, `buttons[${urlButtonIndex}].example[0]`, e.target.value)
          props.onChange(clonedSampleContent)
        }}
      />
    </Fragment>
  )
}

export default WhatsappMessageTemplateSampleButtonContent
