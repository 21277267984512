import React, { Fragment, useState } from "react"
import styled from "styled-components"
import _ from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faCircleNotch, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
const SERVER_API_PATH = process.env.REACT_APP_SERVER_API_PATH

export const UploadContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`

export const UploadLabel = styled.label`
  text-align: center;
  height: 100%;
  margin-right: ${props => props.theme.spaceXs};
  position: relative;
`

export const UploadFileButton = styled.div`
  background-color: ${props => props.theme.gray05};
  border-radius: 4px;
  padding: 9px 16px;
  user-select: none;
  text-align: center;
  cursor: ${props => (props.isDisabled ? "not-allowed" : "pointer")};
`

export const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  font-size: 0.8rem;
`

export const FileName = styled.div`
  margin: 0 4px 0 8px;
  font-size: 0.8rem;
`

// export const LoadingIcon = styled(FontAwesomeIcon).attrs(() => ({
//   icon: "circle-notch",
//   spin: true,
//   fixedWidth: true
// }))``

// export const SuccessIcon = styled(FontAwesomeIcon).attrs(() => ({
//   icon: "check-circle",
//   fixedWidth: true
// }))`
//   color: ${props => props.theme.success};
// `

// export const ErrorIcon = styled(FontAwesomeIcon).attrs(() => ({
//   icon: "exclamation-triangle",
//   fixedWidth: true
// }))`
//   color: ${props => props.theme.error};
// `

export const TextField = styled.input`
  font-size: 1em;
  color: #626262;
  outline: none;
  margin-bottom: 3px;
  margin-top: 2px;
  /* margin-left: 4px; */
  margin-right: 4px;
  padding: 6px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  overflow: hidden;
  overflow-wrap: break-word;
  width: 100%;
  box-sizing: border-box;
`

const WhatsappMessageTemplateSampleHeaderModal = ({
  index,
  values,
  file,
  setFile,
  selectedMT,
  info,
  ...props
}) => {
  const [uploadStatus, setUploadStatus] = useState(file ? "success" : "")

  const sampleHeaderText = _.get(selectedMT, "header.example.header_text[0]", "")

  const headerFormat = _.get(selectedMT, "header.format")
  const mediaType = _.get(selectedMT, "header.mediaType")

  const renderUploadStatusIcon = () => {
    let element
    switch (uploadStatus) {
      case "loading":
        element = <FontAwesomeIcon icon={faCircleNotch} spin />
        break
      case "success":
        element = <FontAwesomeIcon icon={faCheckCircle} style={{ color: "#538835" }} />
        break
      case "failure":
        element = <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: "#d93920" }}/>
        break
      default:
    }
    return element
  }

  const handleFileUpload = async (files, type) => {
    setUploadStatus("loading")
    const uploadedFile = files[0]
    if (type === "image") {
      if (!uploadedFile.type.includes("jpeg") && !uploadedFile.type.includes("jpg") && !uploadedFile.type.includes("png")) {
        setUploadStatus("failure")
        return alert("Unsupported image format")
      }
    }

    if (type === "video" && uploadedFile.type !== "video/mp4") {
      setUploadStatus("failure")
      return alert("Unsupported video format")
    }

    if (type === "document" && uploadedFile.type !== "application/pdf") {
      setUploadStatus("failure")
      return alert("Unsupported document format")
    }

    const params = {
      fileName: uploadedFile.name,
      fileType: uploadedFile.type,
      phoneNumber: info?.phoneNumber,
      phoneNumberId: info?.phoneNumberId,
      tags: [{
        key: "Type",
        value: "MediaFileForTemplateSample"
      }]
    }
    try {
      const json = await fetch(`${SERVER_API_PATH}/sign-url`, {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json"
        }
      }).then(response => response.json())
      const form = new FormData()
      if (json.ok) {
        _.forEach(json?.data?.fields, (value, key) => {
          form.append(key, value)
        })
        form.append("file", uploadedFile)
        const uploadToS3Response = await fetch(json.data.url, {
          method: "POST",
          body: form,
        })
        if (uploadToS3Response.status === 204) {
          const split = json?.data?.fields.key.split("/")
          const encodedSplit = split.map(s => encodeURIComponent(s))
          const fileUrl = `${json?.data?.url}/${encodedSplit.join("/")}`
          const upload = await fetch(`${SERVER_API_PATH}/resumable-upload`, {
            method: "POST",
            body: JSON.stringify({
              url: fileUrl
            }),
            headers: {
              "Content-Type": "application/json"
            }
          }).then(response => response.json())
          const clonedSampleContent = _.cloneDeep(selectedMT)
          console.log("upload.handle", upload.handle)
          _.set(clonedSampleContent, "header.example.header_handle[0]", upload.handle)
          props.onChange(clonedSampleContent)
          console.log("clonedSampleContent", clonedSampleContent)
          setUploadStatus("success")
          setFile(uploadedFile)
        }
        return null
      } else {
        throw new Error(json.error)
      }
    } catch (error) {
      setUploadStatus("failure")
      alert(error.message)
    }
    

    // const formData = new FormData()
    // formData.append("uploadedFile", uploadedFile)

    // return fetch(`${serverAPIPath}/whatsapp/resumableUpload`, {
    //   method: "POST",
    //   body: formData,
    // })
    //   .then(response => response.json())
    //   .then((json) => {
    //     console.log("json", json)
    //     if (json.ok) {
    //       const clonedSampleContent = _.cloneDeep(selectedMT)
    //       _.set(clonedSampleContent, "header.example.header_handle[0]", json.file.handle)
    //       props.onChange(clonedSampleContent)

    //       setUploadStatus("success")
    //       setFile(uploadedFile)
    //     } else {
    //       throw new Error(json.error)
    //     }
    //   })
    //   .catch((err) => {
    //     setUploadStatus("failure")
    //     alert(err)
    //   })
  }

  const getFileAcceptType = (type) => {
    if (type === "image") {
      return ".png, .jpg, .jpeg"
    }

    if (type === "video") {
      return ".mp4"
    }

    return ".pdf"
  }

  return (
    <Fragment>
      {headerFormat === "text" ? 
        <TextField
          label="{{1}}"
          value={sampleHeaderText}
          onChange={(e) => {
            const clonedSampleContent = _.cloneDeep(selectedMT)
            _.set(clonedSampleContent, "header.example.header_text[0]", e.target.value)
            props.onChange(clonedSampleContent)
          }}
        />
        :
        <UploadContainer>
          <UploadLabel htmlFor="upload-file">
            <UploadFileButton
              isDisabled={!mediaType}
            >
              Add Sample
            </UploadFileButton>
            <FileInput
              type="file"
              id="upload-file"
              onChange={(e) => {
                setUploadStatus("")
                handleFileUpload(e.target.files, mediaType)
              }}
              name={`messageTemplates[${index}].header.example.header_handle[0]`}
              disabled={!mediaType}
              accept={getFileAcceptType(mediaType)}
            />
          </UploadLabel>
          {file ? (
            <FileName>{file.name}</FileName>
          ) : (
            <FileName>No file selected</FileName>
          )}
          {renderUploadStatusIcon()}
        </UploadContainer>
      }
    </Fragment>
  )
}

export default WhatsappMessageTemplateSampleHeaderModal
