import styled from "styled-components"
import ReactTooltip from "react-tooltip"

import Table from "../Radiate/Table/Table"
// import { Link } from "found"

export const StyledTooltip = styled(ReactTooltip)`
  padding: 6px 8px !important;
  font-weight: normal !important;

  &:before, &:after {
    border-width: 0px !important;
  }
`

export const ButtonPreview = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  margin: 8px 0;
  padding: 4px;

  td {
    font-size: ${props => props.theme.textSm};
  }
`

export const TableLabel = styled.td`
  font-size: ${props => props.theme.textSm};
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 4px;
`

export const TooltipContent = styled.div`
  white-space: pre-wrap;
`

export const TopBar = styled.div`
  box-sizing: border-box;

  .message-template-top-bar {
    display: flex;
    padding-top: 16px;
    justify-content: space-between;
  }

  >.separator {
    height: 1px;
    width: 100%;
    background-color: ${props => props.theme.gray1};
  }

  .additional-options-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    color: ${props => props.theme.gray6};

    .integration-section {
      display: flex;
      align-items: center;
    }

    .integration-selector {
      margin: 0 16px;
      width: 500px;
    }
  }
`

export const WhatsappMessageTemplateContainer = styled.div`
  box-sizing: border-box;
  height: calc(100% - 50px);
`

export const Pagination = styled.div`
  display: flex;
  justify-content: flex-end;
`

// export const SegmentedButtonContainer = styled.div`
//   display: flex;
//   align-items: center;
//   margin: 0 8px;
//   .box-button-container {
//     border-radius: 0;
//     border-left: none;
//     &:first-child {
//       border-left: 1px solid ${props => props.theme.gray2};
//       border-top-left-radius: 4px;
//       border-bottom-left-radius: 4px;

//       &:hover {
//         border-color: ${props => props.theme.gray1};
//       }
//     }
//     &:last-child {
//       border-top-right-radius: 4px;
//       border-bottom-right-radius: 4px;
//     }
//   }
// `

export const TableContainer = styled.div`
  border: 1px solid ${props => props.theme.gray1};
  border-radius: 4px;
  overflow-x: scroll;
  overflow-y: scroll;
  margin-top: 8px;
  max-height: 100%;

  /* .whatsapp-message-templates-table {
    border-bottom: ${props => props.noHeight ? "none" : `1px solid ${props.theme.gray1}`};
  } */
`

export const StyledTable = styled(Table)`
  .accessToken {
    line-break: anywhere;
  }
`

export const Status = styled.div`
  box-sizing: border-box;
  min-height: 28px;
  display: inline-flex;
  align-items: center;
  margin: 4px 8px 4px 0;
  background-color: ${props => props.theme.paleBackground};
  border-radius: 4px;
  padding: 4px 8px;
  box-sizing: border-box;
  color: ${(props) => {
    switch (props.status) {
      case "APPROVED":
        return props.theme.success
      case "PENDING":
        return props.theme.warning
      case "REJECTED":
        return props.theme.error
      case "PENDING_DELETION":
        return props.theme.lightText
      case "DELETED":
        return props.theme.darkText
      default:
        break
    }
  }};

  .language-status-wrapper {
    align-items: center;
    display: flex;
    /* min-width: 120px; */
    

    .icon {
      padding-right: 4px;
      font-size: ${props => props.theme.textSm};
    }

    .language {
      color: ${props => props.theme.gray6};
      font-weight: bold;
      flex-shrink: 0;
    }
  }

  .text {
    margin-left: 24px;
    white-space: pre-wrap;
    color: ${props => props.theme.gray6};
  }
`

export const LoadMoreRow = styled.div`
  display: flex;
  justify-content: center;
  button {
    padding: 18px;
    width: 100%;
  }
`


export const StyledLink = styled.a`
  &:link {
    color: inherit;
    text-decoration: none;
  }

  &:visited {
    color: inherit;
    text-decoration: none;
  }
`
export const MessageTemplateContainer = styled.div`
  padding: ${props => props.createPage ? "0 16px" : "0 16px 40px 16px"};
`

export const SegmentedButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 8px;
  .box-button-container {
    border-radius: 0;
    border-left: none;
    &:first-child {
      border-left: 1px solid ${props => props.theme.gray2};
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`

export const LoadingContainer = styled.div`
  min-height: 200px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  >svg {
    width: 28px !important;
    height: 28px;
    color: ${props => props.theme.gray4};
  }
`