import React, { useEffect, useState, useRef } from "react"
import { Manager, Reference, Popper } from "react-popper"
import styled from "styled-components"

import NewBoxButton from "../../Radiate/NewBoxButton/NewBoxButton"


const DropDownContainer = styled.div`
  border-radius: 4px;
  background: "white";
  border: 1px solid ${props => props.theme.gray1};
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${props => props.noPadding ? "0" : "4px"};

  .drop-down-button {
    justify-content: flex-start;

    &:hover {
      background: ${props => props.theme.palerBackground};
    }
  }
`

const StyledBoxButton = styled(NewBoxButton)`
  z-index: 99;
`

const DropdownMenu = ({
  className,
  text,
  icon,
  buttons,
  disabled,
  inverted,
  borderless,
  primary,
  danger,
  placement,
  modifiers,
  positionFixed,
  hideOutOfBoundaries = true,
}) => {
  const [ showMenu, setShowMenu ] = useState(false)
  const dropdownContainerRef = useRef(null)
  const dropdownButtonRef = useRef(null)

  useEffect(() => {
    const handleClick = (e) => {
      if (dropdownContainerRef.current && dropdownContainerRef.current.contains(e.target)) {
        return
      }
      if (dropdownButtonRef.current && dropdownButtonRef.current.contains(e.target)) {
        return
      }
      setShowMenu(false)
    }
    document.addEventListener("mousedown", handleClick, false)

    return () => document.removeEventListener("mousedown", handleClick, false)
  }, [])

  let styledTop = "4px"
  if (placement === "left" || placement === "right") {
    styledTop = "0px"
  }

  return (
    <div className={className}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <span style={{ display: "inline-block" }} ref={ref}>
              <span ref={dropdownButtonRef}>
                <StyledBoxButton
                  text={text}
                  dropDownIcon
                  inverted={inverted}
                  borderless={borderless}
                  primary={primary}
                  danger={danger}
                  disabled={disabled}
                  onClick={(e) => {
                    e.stopPropagation()
                    setShowMenu(!showMenu)
                  }}
                />
              </span>
            </span>
          )}
        </Reference>
        {showMenu ?
          <Popper
            placement={placement}
            modifiers={modifiers}
            positionFixed={positionFixed}
            disabled={disabled}
          >
            {({
              ref, 
              style, 
              outOfBoundaries, 
            }) => {
              if (outOfBoundaries && hideOutOfBoundaries) {
                return null
              }
              return (
                <div
                  className="popper-dropdown"
                  ref={ref}
                  style={{ 
                    ...style, 
                    zIndex: 8999,
                    top: styledTop,
                    background: "#ffffff"
                  }}
                >
                  <DropDownContainer
                    ref={dropdownContainerRef}
                    className="dropdown-container"
                    noPadding={buttons.length <= 1}
                  >
                    
                    {buttons.map((o, i) => {
                      return (
                        <NewBoxButton
                          className="drop-down-button"
                          notAuth={o.notAuth}
                          notAuthPlacement={o.notAuthPlacement}
                          key={`${i}`}
                          borderless
                          text={o.label}
                          icon={o.icon}
                          primary={o.primary}
                          danger={o.danger}
                          disabled={o.disabled}
                          onClick={(e) => {
                            e.stopPropagation()
                            o.onClick(e)
                          }}
                        />
                      )
                    })}
                  </DropDownContainer>
                </div>
              )
            }}
          </Popper>
          :
          null
        }
      </Manager>
    </div>
  )
}

export default DropdownMenu