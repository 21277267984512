import React, { Component } from "react"
import _ from "lodash"
// import moment from "moment"
import styled, { css } from "styled-components"
import { setLightness } from "polished"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'


const TableWrapper = styled.div`
  
`
const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  font-size: 0.95rem  !important;
`

const HeaderRow = styled.tr`
  
  th {
    font-size: 0.85rem !important;
    padding: 13px 16px;
    white-space: nowrap;
    color: ${props => (props.darkMode ? props.theme.gray5 : props.theme.gray8)};
    box-sizing: border-box;
    font-weight: normal;
    background-color: ${props => (props.darkMode ? props.theme.gray12 : props.theme.gray0)};
    vertical-align: middle;
    border-top: 1px solid ${props => (props.darkMode ? props.theme.gray10 : props.theme.gray1)};

    &:first-child {
      border-left: 1px solid ${props => (props.darkMode ? props.theme.gray10 : props.theme.gray1)};
      border-top-left-radius: 4px;
    }

    &:last-child {
      border-right: 1px solid ${props => (props.darkMode ? props.theme.gray10 : props.theme.gray1)};
      border-top-right-radius: 4px;
      text-align: right;
    }
  }

  ${(props) => {
    if (props.noOuterBorder) {
      return css`
        th {
          border-top: none;
          &:first-child {
            border-left: none;
          }
          &:last-child {
            border-right: none;
          }
        }
      `
    }
    if (props.simple) {
      return css`
        th {
          border-top: none;
          padding: 8px 16px;
          background: white;
          font-size: ${props.theme.textSm};
          text-transform: uppercase;
          color: ${props.theme.gray5};
          letter-spacing: 0.1px;

          &:first-child {
            border-left: none;
          }
          &:last-child {
            border-right: none;
          }
        }
      `
    }
  }}

  ${(props) => {
    if (props.sortableHeader) {
      return css`
        th {
          &:hover {
            cursor: pointer;
          }

          .sortable-icon.inactive {
            color: ${props => props.theme.gray2};
          }
        }
      `
    }
  }}
`

const SortingHeader = styled.th`
  color: ${props => props.theme.primary} !important;
`

const NoDataRow = styled.tr`
  td {
    text-align: center !important;
    padding: 13px 16px;
    color: ${props => (props.darkMode ? "rgba(255, 255, 255, 0.87)" : props.theme.gray4)};
    border-left: 1px solid ${props => (props.darkMode ? props.theme.gray10 : props.theme.gray1)};
    border-right: 1px solid ${props => (props.darkMode ? props.theme.gray10 : props.theme.gray1)};
    border-bottom: 1px solid ${props => (props.darkMode ? props.theme.gray10 : props.theme.gray1)};
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: ${props => (props.darkMode ? props.theme.gray12 : props.theme.white)};
  }

  ${(props) => {
    if (props.noOuterBorder) {
      return css`
        td {
          border-top: 1px solid ${props.darkMode ? props.theme.gray10 : props.theme.gray1};
          &:first-child {
            border-left: none;
          }
          &:last-child {
            border-right: none;
          }
        }
        &:last-of-type {
          td {
            border-bottom: none;
          }

          &:hover td {
            border-bottom: none;
          }
        }
      `
    }
    if (props.simple) {
      return css`
        td {
          border-top: 1px solid ${props.darkMode ? props.theme.gray10 : props.theme.gray1};
          &:first-child {
            border-left: none;
          }
          &:last-child {
            border-right: none;
          }
        }
      `
    }
  }}

`

const ContentRow = styled.tr`
  td {
    font-size:0.8rem;
    box-sizing: border-box;
    padding: 13px 16px;
    vertical-align: middle;
    font-weight: normal;
    color: ${props => (props.darkMode ? "rgba(255, 255, 255, 0.87)" : props.theme.gray6)};
    border-top: 1px solid ${props => (props.darkMode ? props.theme.gray10 : props.theme.gray1)};
    background-color: ${props => (props.darkMode ? props.theme.gray12 : "props.theme.white")};

    &:first-child {
      color: ${props => (props.darkMode ? "rgba(255, 255, 255, 0.87)" : props.theme.gray7)};
      border-left: 1px solid ${props => (props.darkMode ? props.theme.gray10 : props.theme.gray1)};
    }
    &:last-child {
      border-right: 1px solid ${props => (props.darkMode ? props.theme.gray10 : props.theme.gray1)};
      text-align: right;
    }
  }

  &.selected {
    td {
      background-color: ${props => setLightness(0.69, props.theme.themeColorLight)};
      color: ${props => props.theme.white};
    }

    &:hover, &:active {
      td {
        background-color: ${props => setLightness(0.75, props.theme.themeColorLight)};
        color: ${props => props.theme.white};
      }
    }
  }

  &:last-of-type {
    td {
      border-bottom: 1px solid ${props => (props.darkMode ? props.theme.gray10 : props.theme.gray1)};

      &:first-child {
        border-bottom-left-radius: 4px;
      }
      &:last-child {
        border-bottom-right-radius: 4px;
      }
    }

    &:hover td {
      border-bottom: 1px solid ${props => (props.darkMode ? props.theme.gray10 : setLightness(0.88, props.theme.primary))};
    }
  }

  &:hover {
    td {
      background: ${props => (props.darkMode ? props.theme.gray11 : setLightness(0.98, props.theme.primary))};
      border-top: 1px solid ${props => (props.darkMode ? props.theme.gray10 : setLightness(0.88, props.theme.primary))};
    }

    & + tr {
      td {
        border-top: 1px solid ${props => (props.darkMode ? props.theme.gray10 : setLightness(0.88, props.theme.primary))};
      }
    }
  }

  ${(props) => {
    if (props.noOuterBorder) {
      return css`
        td {
          &:first-child {
            border-left: none;
          }
          &:last-child {
            border-right: none;
          }
        }
        &:last-of-type {
          td {
            border-bottom: none;
          }

          &:hover td {
            border-bottom: none;
          }
        }
      `
    }
    if (props.simple) {
      return css`
        td {
          &:first-child {
            border-left: none;
          }
          &:last-child {
            border-right: none;
          }
        }
      `
    }
  }}
`

class Table extends Component {
  constructor(props) {
    super(props)

    this.state = {
      keys: this.getKeys(props) || []
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (_.isEmpty(this.props.displayFields) && this.props.data && !_.isEqual(prevProps.data, this.props.data)) {
      this.setState({
        keys: this.getKeys(this.props)
      })
    }
  }

  getKeys(props) {
    if (props.data) {
      const keys = props.data.reduce((sum, o) => [...sum, ..._.keys(o)], [])
      return _.uniq(keys)
    }
    return []
  }

  headerRow() {
    if (this.props.displayFields && this.props.displayFields.length) {
      return this.props.displayFields.map((field, index) => {
        if (this.props.sortableHeader && field.sortable !== false) {
          let key = field.key
          if (_.isArray(field.key)) {
            key = field.key.join(",")
          }
          if (_.has(this.props.sortBy, key)) {
            let nextSortDirection = 1
            if (this.props.sortBy[key] === 1) {
              nextSortDirection = -1
            }
            return (
              <SortingHeader
                key={index}
                className={field.className ? field.className : field.key}
                onClick={() => {
                  const obj = {}
                  obj[key] = nextSortDirection
                  if (this.props.onSort) this.props.onSort(obj)
                }}
              >
                <span>{field.label}</span>
                <FontAwesomeIcon
                  className="sortable-icon"
                  icon={this.props.sortBy[key] === 1 ? faCaretUp : faCaretDown}
                  fixedWidth
                />
              </SortingHeader>
            )
          }
          return (
            <th
              key={index}
              className={field.className ? field.className : field.key}
              onClick={() => {
                const obj = {}
                obj[key] = 1
                if (this.props.onSort) this.props.onSort(obj)
              }}
            >
              <span>{field.label}</span>
              <FontAwesomeIcon
                className="sortable-icon inactive"
                icon={faCaretDown}
                fixedWidth
              />
            </th>
          )
        }
        return (
          <th
            key={index}
            className={field.className ? field.className : field.key}
          >
            {field.label}
          </th>
        )
      })
    } else if (this.props.data) {
      const views = []
      if (!this.props.data.length) {
        return views
      }
      if (this.props.additionalFieldsUnshift) {
        this.props.additionalFieldsUnshift.forEach((field, index) => {
          views.push((
            <th
              key={index}
              className={field.className ? field.className : field.key}
            >
              {field.label}
            </th>
          ))
        })
      }
      this.state.keys.forEach((o) => {
        if (this.props.sortableHeader) {
          if (_.has(this.props.sortBy, o)) {
            let nextSortDirection = 1
            if (this.props.sortBy[o] === 1) {
              nextSortDirection = -1
            }
            views.push((
              <SortingHeader
                key={o}
                className={o}
                onClick={() => {
                  const obj = {}
                  obj[o] = nextSortDirection
                  if (this.props.onSort) this.props.onSort(obj)
                }}

              >
                <span>{o}</span>
                <FontAwesomeIcon
                  className="sortable-icon"
                  icon={this.props.sortBy[o] === 1 ? faCaretUp : faCaretDown}
                  fixedWidth
                />
              </SortingHeader>
            ))
          } else {
            views.push((
              <th
                key={o}
                className={o}
                onClick={() => {
                  const obj = {}
                  obj[o] = 1
                  if (this.props.onSort) this.props.onSort(obj)
                }}
              >
                <span>{o}</span>
                <FontAwesomeIcon
                  className="sortable-icon inactive"
                  icon={faCaretDown}
                  fixedWidth
                />
              </th>
            ))
          }
        } else {
          views.push((
            <th
              key={o}
              className={o}
            >
              {o}
            </th>
          ))
        }
      })
      return views
    }
    return null
  }

  // valueNormalizer(value, key) {
  //   if (typeof value === "boolean") {
  //     return JSON.stringify(value)
  //   }
  //   if (_.isArray(value) || _.isObject(value)) {
  //     return JSON.stringify(value, null, 2)
  //   }
  //   if (_.isFinite(value) && /At$/.test(key) && moment(value).isValid()) {
  //     return moment(value).format("D MMM YYYY LTS Z")
  //   }
  //   return value
  // }

  contentRow(doc, index) {
    if (this.props.displayFields && this.props.displayFields.length) {
      return this.props.displayFields.map((field, i) => {
        if (field.custom) {
          let customParams = [_.get(doc, field.key)]
          if (_.isArray(field.key)) {
            customParams = field.key.map(o => _.get(doc, o))
          }
          return (
            <td
              key={i}
              className={field.className ? field.className : field.key}
            >
              {field.custom(...customParams, index)}
            </td>
          )
        }
        let value = _.get(doc, field.key)
        if (_.isArray(field.key)) {
          value = field.key.reduce((total, current) => `${total} ${_.get(doc, `${current}.length`) ? _.get(doc, current, "") : ""}`, "")
        }
        // array.reduce(loop func, initialValue)
        return (
          <td
            key={i}
            className={field.className ? field.className : field.key}
          >
            {value}
            {/* {this.valueNormalizer(value, field.key)} */}
          </td>
        )
      })
    }
    const views = []
    if (this.props.additionalFieldsUnshift) {
      this.props.additionalFieldsUnshift.forEach((field, i) => {
        if (field.custom) {
          let customParams = [_.get(doc, field.key)]
          if (_.isArray(field.key)) {
            customParams = field.key.map(o => _.get(doc, o))
          }
          views.push((
            <td
              key={field}
              className={field.className ? field.className : field.key}
            >
              {field.custom(...customParams, index)}
            </td>
          ))
          return
        }
        let value = _.get(doc, field.key)
        if (_.isArray(field.key)) {
          value = field.key.reduce((total, current) => `${total} ${_.get(doc, `${current}.length`) ? _.get(doc, current, "") : ""}`, "")
        }
        // array.reduce(loop func, initialValue)
        views.push((
          <td
            key={field}
            className={field.className ? field.className : field.key}
          >
            {/* {this.valueNormalizer(value, field.key)} */}
            {value}
          </td>
        ))
      })
    }
    this.state.keys.forEach((key, i) => {
      views.push((
        <td
          key={i}
          className={key}
        >
          {/* {this.valueNormalizer(doc[key], key)} */}
          {doc[key]}
        </td>
      ))
    })
    return views
  }

  tableContent(propsForDataRow) {
    if (!_.get(this.props, "data.length")) {
      return (
        <NoDataRow
          {...propsForDataRow}
        >
          <td
            colSpan={_.get(this.props, "displayFields.length", 0)}
          >
            {this.props.noDataText || "No Data"}
          </td>

        </NoDataRow>
      )
    }
    const {
      selectable,
      selected,
      onChange,
      customKey,
      onSelect,
      selectedRow,
      onClick,
      noOuterBorder = false,
      sortableHeader = false,
      simple = false,
    } = this.props
    const propsToPass = {
      noOuterBorder,
      simple,
      sortableHeader,
    }
    return this.props.data.map((doc, index) => {
      let isSelected = false
      if (selectable && selected.indexOf(doc._id) > -1) {
        isSelected = true
      }

      let key
      if (customKey && _.isArray(customKey)) {
        key = customKey.reduce((memo, current) => `${memo}${_.get(doc, current)}`, "")
      } else if (customKey) {
        key = _.get(doc, customKey)
      } else {
        key = doc._id
      }

      if (selectedRow) {
        if (selectedRow === key) {
          isSelected = true
        }
      }

      return (
        <ContentRow
          {...propsToPass}
          {...propsForDataRow}
          className={`${isSelected ? "selected" : ""} ${selectable ? "selectable" : ""}`}
          key={key}
          onClick={() => {
            const selection = window.getSelection().toString()
            if (!selection) {
              // Not highlight text
              if (selectable) {
                if (isSelected) {
                  const result = selected.filter(item => item !== doc._id)
                  if (onChange) onChange(result)
                } else {
                  const result = [...selected, doc._id]
                  if (onChange) onChange(result)
                }
              } else {
                if (onSelect) onSelect(doc._id)
                if (onClick) {
                  onClick(doc)
                }
              }
            }
          }}
        >
          {this.contentRow(doc, index)}
        </ContentRow>
      )
    })
  }

  render() {
    // console.log(this.props.data)

    const {
      className = "",
      darkMode = false,
      noOuterBorder = false,
      sortableHeader = false,
      simple = false,
    } = this.props
    const propsToPass = {
      noOuterBorder,
      simple,
      sortableHeader,
      darkMode
    }
    // const selectable = this.props.selectable
    return (
      <TableWrapper className={`table-container ${className}`}>
        <StyledTable>
          <thead>
            <HeaderRow {...propsToPass}>
              {this.headerRow()}
            </HeaderRow>
          </thead>
          <tbody>
            {this.tableContent(propsToPass)}
          </tbody>
        </StyledTable>
      </TableWrapper>
    )
  }
}

export default Table
