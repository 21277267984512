import React, { Fragment, useState } from "react"
import Select from "react-select"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle, faCircle, faTimes, faLink, faPhone, faImage, faPlayCircle, faFileAlt } from '@fortawesome/free-solid-svg-icons'

import _ from "lodash"

import { Form } from "react-final-form"
import { FORM_ERROR } from "final-form"
import arrayMutators from "final-form-arrays"
import { FieldArray } from "react-final-form-arrays"
import * as Styles from "./CreateMessageTemplateStyles"

import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"
import SampleButton from "../SampleButton/SampleButton"
import Modal from "../Radiate/Modal/Modal"
import WhatsappMessageTemplateSampleModal from "../WhatsappMessageTemplateSampleModal/WhatsappMessageTemplateSampleModal"

import { categoryMTOptions, languageOptions, randomString } from "../util"

import { whatsappMessageTemplateSchema } from "./whatsappMessageTemplateSchema"
import WhatsappChatBubble from "../WhatsappChatBubble/WhatsappChatBubble"
const SERVER_API_PATH = process.env.REACT_APP_SERVER_API_PATH

const modLanguageOptions = languageOptions.map((o) => {
  return ({
    label: `${o.label} [${o.value}]`,
    value: o.value
  })
})

function mapYupValidationError(error, errorObj = {}) {
  error.inner.forEach((e) => {
    if (_.isArray(e.value)) {
      _.set(errorObj, e.path, [e.message])
    } else {
      _.set(errorObj, e.path, e.message)
    }
    if (e.inner.length) {
      mapYupValidationError(e, errorObj)
    }
  })
  return errorObj
}

const submitWhatsappMessageTemplate = async ({
  name,
  category,
  components,
  language,
  payload,
  signedContext,
}) => {
  let body = {
    payload,
    signedContext,
    name,
    category,
    components,
    language
  }
  const result = await fetch(`${SERVER_API_PATH}/create-whatsapp-message-template`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body)
  }).then(res => res.json())
  return result

}

const CreateMessageTemplate = ({
  onChangePage,
  payload,
  signedContext,
  info
}) => {
  const [openModal, setOpenModal] = useState(false)
  const [modalContent, setModalContent] = useState(null)
  const [file, setFile] = useState(null)

  const headerOptions = [
    { value: "none", label: "None" },
    { value: "image", label: "Image" },
    { value: "video", label: "Video" },
    { value: "document", label: "Document" },
    { value: "text", label: "Text" }
  ]

  const buttonsTypeOptions = [
    { value: "None", label: "None" },
    { value: "Quick Reply", label: "Quick Reply" },
    { value: "Call To Action", label: "Call To Action" },
  ]

  const buttonTypeOptions = [
    { value: "URL", label: "Visit Website" },
    { value: "PHONE_NUMBER", label: "Call Phone Number" },
  ]

  const getLanguageOptions = messageTemplates => modLanguageOptions.map((o) => {
    if (messageTemplates.findIndex(t => t.language === o.value) > -1) {
      return {
        ...o,
        isDisabled: true
      }
    }
    return o
  })

  const changeLanguageFormat = (languageCode) => {
    if (!languageCode) {
      return null
    }

    const selectedOption = modLanguageOptions.find(language => languageCode === language.value)
    return selectedOption.label
  }

  const disableHeaderSampleButton = ({ selectedHeaderFormat, values, index }) => {
    const wrongHeaderFormat = selectedHeaderFormat !== "media" && selectedHeaderFormat !== "text"
    if (wrongHeaderFormat) {
      return true
    }
    if (selectedHeaderFormat === "media") {
      return false
    }
    if (selectedHeaderFormat === "text") {
      const headerText = _.get(values, `messageTemplates[${index}].header.text`, "")
      const regexResult = /{{(\d{1,2})}}/.test(headerText)
      const includeResult = headerText.includes("{{1}}")
      if (regexResult && includeResult) return false
    }
    return true
  }

  const disableBodySampleButton = ({ messageTemplate }) => {
    if (_.isEmpty(messageTemplate)) return true
    const bodyText = _.get(messageTemplate, "body.text", "")

    if (bodyText && bodyText !== "") {
      const regexResult = /{{(\d{1,2})}}/.test(bodyText)
      const includeResult = bodyText.includes("{{1}}")
      if (regexResult && includeResult) return false
    }
    return true
  }

  const disableButtonSampleButton = ({ messageTemplate }) => {
    const buttons = _.get(messageTemplate, "buttons")
    if (!buttons) return true

    const ctaButton = buttons.find(b => b.type === "URL")
    if (!ctaButton) return true

    const url = _.get(ctaButton, "url", "")
    const httpRegex = /^https?:\/\//
    if (url.includes("{{1}}") && httpRegex.test(url)) {
      return false
    }

    return true
  }

  function renderButton({
    button,
    buttons,
    index,
    canRemove,
    remove,
    change,
    arrayPath,
    path,
  }) {
    if (button.type === "QUICK_REPLY") {
      return (
        <Styles.QuickReplyButton key={button.tempId}>
          <Styles.StyledTextField
            label="Button Text"
            width="calc(100% - 32px)"
            name={`${path}.text`}
            useInput
            maxLength={20}
            borderStyle
          />
          {!canRemove ?
            null
            :
            <FontAwesomeIcon
              icon={faTimes}
              style={{
                padding: "8px",
                position: "absolute",
                cursor: "pointer",
                top: "4px",
                right: "4px",
                color: "#a5a5a6"
              }}
              onClick={() => {
                const newButtonsArray = _.cloneDeep(buttons)
                newButtonsArray.splice(index, 1)
                change(arrayPath, newButtonsArray)
              }}
            />
          }
        </Styles.QuickReplyButton>
      )
    }
    if (button.type === "PHONE_NUMBER") {
      return (
        <Styles.QuickReplyButton key={button.tempId}>
          <Styles.StyledSelectField
            label="Type of Action"
            name={`${path}.type`}
            options={buttonTypeOptions.map((o) => {
              console.log("o", o)
              console.log("buttons", buttons)
              if (buttons.find(obj => obj.type === o.value)) {
                return {
                  ...o,
                  isDisabled: true
                }
              }
              return o
            })}
          />
          <Styles.StyledTextField
            label="Button Text"
            name={`${path}.text`}
            useInput
            borderStyle
            maxLength={20}
          />
          <Styles.StyledTextField
            label="Phone Number"
            name={`${path}.phone_number`}
            useInput
            borderStyle
          />
          {!canRemove ?
            null
            :
            <FontAwesomeIcon
              icon={faTimes}
              style={{
                padding: "8px",
                position: "absolute",
                cursor: "pointer",
                top: "4px",
                right: "4px",
                color: "#a5a5a6"
              }}
              onClick={() => {
                const newButtonsArray = _.cloneDeep(buttons)
                newButtonsArray.splice(index, 1)
                change(arrayPath, newButtonsArray)
              }}
            />
          }
        </Styles.QuickReplyButton>
      )
    }
    if (button.type === "URL") {
      return (
        <Styles.QuickReplyButton key={button.tempId}>
          <Styles.StyledSelectField
            label="Type of Action"
            name={`${path}.type`}
            options={buttonTypeOptions.map((o) => {
              if (buttons.find(obj => obj.type === o.value)) {
                return {
                  ...o,
                  isDisabled: true
                }
              }
              return o
            })}
          />
          <Styles.StyledTextField
            label="Button Text"
            name={`${path}.text`}
            borderStyle
            useInput
            maxLength={20}
          
          />
          <Styles.StyledTextField
            label="URL"
            name={`${path}.url`}
            useInput
            borderStyle
            className="buttons-url-text-field"
          
            tooltipText="If you are a static URL, you can type your URL as https://stella.sanuker.com/. <br />Meanwhile if you want a dynamic URL, you can type your URL as e.g. https://stella.sanuker.com/{{1}}."
          />
          {!canRemove ?
            null
            :
            <FontAwesomeIcon
              icon={faTimes}
              style={{
                padding: "8px",
                position: "absolute",
                cursor: "pointer",
                top: "4px",
                right: "4px",
                color: "#a5a5a6"
              }}
              onClick={() => {
                const newButtonsArray = _.cloneDeep(buttons)
                newButtonsArray.splice(index, 1)
                change(arrayPath, newButtonsArray)
              }}
            />
          }
        </Styles.QuickReplyButton>
      )
    }
    return null
  }

  function renderHeader(header, selectedHeaderMediaType) {
    if (!header) {
      return null
    }
    if (header.text) {
      return (
        <div style={{ fontWeight: "bold", marginBottom: "4px", wordBreak: "break-word"}}>{header.text}</div>
      )
    }
    if (header.format === "media") {
      switch (selectedHeaderMediaType) {
        case "image":
          return (
            <Styles.PreviewIconContainer>
              <Styles.MediaIcon icon={faImage} size="6x" fixedWidth />
            </Styles.PreviewIconContainer>
          )
        case "video":
          return (
            <Styles.PreviewIconContainer>
              <Styles.MediaIcon icon={faPlayCircle} size="6x" fixedWidth />
            </Styles.PreviewIconContainer>
          )
        case "document":
          return (
            <Styles.PreviewIconContainer>
              <Styles.MediaIcon icon={faFileAlt} size="6x" fixedWidth />
            </Styles.PreviewIconContainer>
          )
        default:
          return null
      }
    }
    return null
  }

  function renderButtonsBubble(buttons, selectedButtonType) {
    if (selectedButtonType === "Quick Reply" && _.get(buttons, "length")) {
      return (
        <Styles.OutsideButtonsContainer>
          {buttons.map(button => (
            <Styles.WhatsappButton key={button.tempId}>{button.text}</Styles.WhatsappButton>
          ))}
        </Styles.OutsideButtonsContainer>
      )
    }
    if (selectedButtonType === "Call To Action" && _.get(buttons, "length")) {
      return (
        <Styles.InnerButtonsContainer>
          <Styles.Separator />
          {buttons.map((button) => {
            switch (button.type) {
              case "URL":
                return (
                  <Styles.CTAWhatsappButton key={button.type}>
                    <FontAwesomeIcon icon={faLink} style={{ marginRight: "4px" }}/>
                    {button.text}
                  </Styles.CTAWhatsappButton>
                )
              case "PHONE_NUMBER":
                return (
                  <Styles.CTAWhatsappButton key={button.type}>
                    <FontAwesomeIcon icon={faPhone} style={{ marginRight: "4px" }}/>
                    {button.text}
                  </Styles.CTAWhatsappButton>
                )
              default:
                return null
            }
          })}
        </Styles.InnerButtonsContainer>
      )
    }
  }

  function submitMessageTemplate({
    name,
    category,
    messageTemplates,
    payload,
    signedContext,
  }) {
    const promises = messageTemplates.map((mt) => {
      const components = []
      if (!_.isEmpty(mt.header)) {
        const header = {
          type: "HEADER",
        }
        if (mt.header.format === "media") {
          header.format = mt.header.mediaType.toUpperCase()
          if (_.get(mt, "header.example.header_handle")) {
            header.example = {
              header_handle: mt.header.example.header_handle
            }
          }
        } else if (mt.header.format === "text") {
          header.format = "TEXT"
          header.text = mt.header.text
          if (_.get(mt, "header.example.header_text") && header.text.includes("{{1}}")) {
            const matchLength = header.text.match(/{{([1-9][0-9]*)}}/g).length
            const newExample = mt.header.example.header_text.slice(0, matchLength)
            header.example = {
              header_text: newExample
            }
          }
        }
        components.push(header)
      }
      if (!_.isEmpty(mt.body)) {
        const body = {
          type: "BODY",
          text: mt.body.text
        }
        if (_.get(mt, "body.example") && body.text.includes("{{1}}")) {
          const matchLength = body.text.match(/{{([1-9][0-9]*)}}/g).length
          const newExample = mt.body.example.body_text[0].slice(0, matchLength)
          body.example = {
            body_text: [newExample]
          }
        }
        components.push(body)
      }
      if (!_.isEmpty(mt.footer)) {
        const footer = {
          type: "FOOTER",
          text: mt.footer.text
        }
        components.push(footer)
      }
      if (_.get(mt, "buttons.length")) {
        const buttons = {
          type: "BUTTONS",
          buttons: mt.buttons.map((button) => {
            if (button.type === "URL" && !button.url.includes("{{1}}")) {
              return _.omit(button, ["tempId", "example"])
            }
            return _.omit(button, ["tempId"])
          })
        }
        components.push(buttons)
      }
      return submitWhatsappMessageTemplate({
        category,
        name,
        language: mt.language,
        components,
        payload,
        signedContext,
      })
    })
    return Promise.all(promises)
  }


  return (
    <div style={{ height: "100%" }}>
      <Form
        style={{ height: "100%" }}
        initialValues={{
          name: "",
          category: "",
          messageTemplates: [],
          selectingIndex: 0,
          selectedButtonType: "",
        }}
        initialValuesEqual={_.isEqual}
        mutators={{
          ...arrayMutators
        }}
        validate={(values) => {
          const {
            name,
            category,
            messageTemplates
          } = values
          try {
            whatsappMessageTemplateSchema.validateSync({
              name,
              category,
              messageTemplates
            }, {
              abortEarly: false
            })
          } catch (error) {
            const errorResult = mapYupValidationError(error)
            return errorResult
          }
        }}
        onSubmit={async (values, { change }) => {
          const {
            name,
            category,
            messageTemplates
          } = values
          messageTemplates.forEach((mt) => {
            if (mt.buttons) {
              const urlButton = mt.buttons.find(b => b.type === "URL")
              if (urlButton) {
                const httpRegex = /^https?:\/\//
                if (urlButton.url.includes("{{1}}") && httpRegex.test(urlButton.url) && urlButton.example) {
                  urlButton.example[0] = `${urlButton.url.replace("{{1}}", "")}${urlButton.example[0]}`
                }
              }
            }
          })

          try {
            const result = await submitMessageTemplate({
              name,
              category,
              messageTemplates,
              payload,
              signedContext,
            })
            let errorString = ""
            let successLangs = []
            result.forEach((o, i) => {
              if (o.error) {
                if (i === 0) {
                  errorString += `${o.language}: [${o?.error?.message}] ${o?.error?.error_user_msg}`
                } else {
                  errorString += `\n\n${o.language}: [${o?.error?.message}] ${o?.error?.error_user_msg}`
                }
              } else {
                successLangs = [...successLangs, o.language]
              }
            })
            if (errorString) {
              const clone = messageTemplates.filter(o => !successLangs.includes(o.language))
              change("messageTemplates", clone)
              return {
                [FORM_ERROR]: errorString
              }
            }
            onChangePage("list")
            return
          } catch (error) {
            return {
              [FORM_ERROR]: error.message
            }
          }
        }}
      >
        {({
          submitError,
          handleSubmit,
          submitting,
          errors,
          values,
          touched,
          form
        }) => {
          const { mutators, change } = form
          const { push } = mutators
          const selectedMessageTemplate = values.messageTemplates[values.selectingIndex] || {}
          const selectedHeaderMediaType = _.get(selectedMessageTemplate, "header.mediaType")
          const selectedHeaderFormat = _.get(selectedMessageTemplate, "header.format")
          const {
            header, body, footer
          } = selectedMessageTemplate
          const buttons = _.get(selectedMessageTemplate, "buttons", [])
         
          return (
            <Styles.StyledForm onSubmit={handleSubmit}>
              <div style={{ display: "flex", padding: "16px 0", justifyContent: "space-between", borderBottom: "1px solid #e8e7e8" }}>
                <NewBoxButton
                  style={{ fontSize: "1rem"}}
                  type="button"
                  primary
                  inverted
                  text="Back to Message Template List"
                  onClick={() => {
                    onChangePage("list")
                  }}
                />
                <Styles.SubmitButton
                  primary
                  type="submit"
                  text="Submit"
                  loading={submitting}
                  className="bottom-bar-button"
                />
              </div>
              <Styles.MessageTemplateContent className="whatsapp-message-template-content">
                <div style={{ fontSize: "0.8rem", marginBottom: "16px" }}>Message templates can&apos;t be edited after they have been submitted for approval.</div>
                <Styles.Card>
                  <Styles.InfoWrapper>
                    <Styles.StyledTextField
                      useInput
                      width="calc(100% - 316px)"
                      type="text"
                      label="Name"
                      name="name"
                    />
                    <Styles.CategorySelectField
                      label="Category"
                      name="category"
                      options={categoryMTOptions}
                    />
                  </Styles.InfoWrapper>
                </Styles.Card>

                <Styles.SectionContainer>
                  <Styles.LeftSection>
                    <Styles.Card>
                      <Styles.LanguageContainer>
                        <div style={{ fontWeight: "bold", padding: "4px 4px 4px 0" }}>Language</div>
                        <FieldArray
                          name="messageTemplates"
                        >
                          {({ fields, meta }) => (
                            fields.map((name, index) => {
                              const field = fields.value[index]
                              return (
                                <Styles.Language
                                  key={field.language}
                                  onClick={() => change("selectingIndex", index)}
                                  selected={values.selectingIndex === index}
                                >
                                  <span>
                                    <FontAwesomeIcon icon={faCircle} style={{ marginRight: "8px"}} />
                                    {changeLanguageFormat(field.language)}
                                  </span>
                                  <FontAwesomeIcon
                                    icon={faTimes}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      change("selectingIndex", values.messageTemplates.length - 2)
                                      fields.remove(index)
                                    }}
                                  />
                                </Styles.Language>
                              )
                            })
                          )}
                        </FieldArray>
                        <Select
                          className="language-selector"
                          placeholder="Add Language"
                          value={null}
                          options={getLanguageOptions(values.messageTemplates)}
                          onChange={(option) => {
                            change("selectingIndex", values.messageTemplates.length)
                            change("selectedButtonType", "")
                            push("messageTemplates", { language: option.value, body: { text: "" } })
                          }}
                        />
                        {errors && typeof _.get(errors.messageTemplates, "[0]") === "string" ?
                          <Styles.StyledErrorMessage>{errors.messageTemplates[0]}</Styles.StyledErrorMessage>
                          :
                          null
                        }
                      </Styles.LanguageContainer>
                    </Styles.Card>
                    {submitError && <Styles.ErrorMessageList>{submitError}</Styles.ErrorMessageList>}
                  </Styles.LeftSection>

                  <Styles.MiddleSection>
                    <Styles.Card>
                      <div style={{ fontWeight: "bold", padding: "4px 4px 4px 0" }}>{`${changeLanguageFormat((selectedMessageTemplate || {}).language) || ""} Language Setting`}</div>
                    </Styles.Card>

                    <Styles.Card>
                      <Styles.HeadingContainer>
                        <div style={{ fontWeight: "bold", padding: "4px 4px 4px 0" }}>Header</div>
                        <Styles.OptionalLabel>Optional</Styles.OptionalLabel>
                        <SampleButton
                          text="Add Sample"
                          onClick={() => {
                            setModalContent("header")
                            setOpenModal(true)
                          }}
                          isDisabled={disableHeaderSampleButton({ selectedHeaderFormat, values, index: values.selectingIndex })}
                        />
                      </Styles.HeadingContainer>
                      <div style={{ fontSize: "0.7rem", margin: "8px 0 4px 0"}}>Add a title or choose which type of media you&apos;ll use for this header.</div>
                      <Styles.StyledSelectField
                        name={selectedMessageTemplate?.header?.format === "text" ? `messageTemplates[${values.selectingIndex}].header.format` : `messageTemplates[${values.selectingIndex}].header.mediaType`}
                        options={headerOptions}
                        isDisabled={_.isEmpty(selectedMessageTemplate)}
                        onChange={(value) => {
                          const clone = _.cloneDeep(selectedMessageTemplate)
                          if (value === "text") {
                            clone.header = {
                              format: "text",
                              text: ""
                            }
                          } else if (value === "image") {
                            clone.header = {
                              format: "media",
                              mediaType: "image"
                            }
                          } else if (value === "video") {
                            clone.header = {
                              format: "media",
                              mediaType: "video"
                            }
                          } else if (value === "document") {
                            clone.header = {
                              format: "media",
                              mediaType: "document"
                            }
                          } else {
                            delete clone.header
                          }
                          change("messageTemplates", [
                            ...values.messageTemplates.slice(0, values.selectingIndex),
                            clone,
                            ...values.messageTemplates.slice(values.selectingIndex + 1)
                          ])
                        }}
                      />
                      {_.get(selectedMessageTemplate, "header.format") === "text" ? (
                        <Fragment>
                          <Styles.HeadingContainer>
                            <div style={{ fontSize: "0.8rem", padding: "8px 0", marginRight: "4px" }}>{`Enter text in ${changeLanguageFormat((selectedMessageTemplate || {}).language)}`}</div>
                            <FontAwesomeIcon
                              icon={faQuestionCircle}
                              className="header-text-tooltip-icon"
                              data-tip="To use variables, a numbered placeholder to indicate the placeholder {{#}} must be used. For each section, it must start with {{1}}. For Header, there is 1 variable is allowed."
                              data-for="whatsapp-message-template__header-text-icon"
                            />
                            <Styles.StyledTooltip
                              offset={{ top: -6 }}
                              id="whatsapp-message-template__header-text-icon"
                              effect="solid"
                              multiline
                            />
                          </Styles.HeadingContainer>
                          <Styles.StyledTextField
                            borderStyle
                            maxLength={60}
                            name={`messageTemplates[${values.selectingIndex}].header.text`}
                          />
                        </Fragment>
                      ) : null}
                    </Styles.Card>

                    <Styles.Card>
                      {/* <Styles.TextButtonWrapper> */}
                        <Styles.HeadingContainer>
                          <div div style={{ fontWeight: "bold", padding: "4px 4px 4px 0" }}>Body</div>
                          <FontAwesomeIcon
                            icon={faQuestionCircle}
                            className="body-tooltip-icon"
                            data-tip="To use variables, a numbered placeholder to indicate the placeholder {{#}} must be used. For each section, it must start with {{1}}."
                            data-for="whatsapp-message-template__body-text-icon"
                          />
                          <Styles.StyledTooltip
                            offset={{ top: -6 }}
                            id="whatsapp-message-template__body-text-icon"
                            effect="solid"
                            multiline
                          />
                          <SampleButton
                            text="Add Sample"
                            onClick={() => {
                              setModalContent("body")
                              setOpenModal(true)
                            }}
                            isDisabled={disableBodySampleButton({ messageTemplate: selectedMessageTemplate })}
                          />
                        </Styles.HeadingContainer>
                        <div style={{ fontSize: "0.7rem", marginTop: "16px" }}>Enter the text for your message in the language you&apos;ve selected.</div>
                      {/* </Styles.TextButtonWrapper> */}
                      <Styles.StyledTextField
                        borderStyle
                        disabled={_.isEmpty(selectedMessageTemplate)}
                        name={`messageTemplates[${values.selectingIndex}].body.text`}
                        maxLength={1024}
                      />
                    </Styles.Card>

                    <Styles.Card>
                      <Styles.HeadingContainer>
                        <div div style={{ fontWeight: "bold", padding: "4px 4px 4px 0" }}>Footer</div>
                        <Styles.OptionalLabel>Optional</Styles.OptionalLabel>
                      </Styles.HeadingContainer>
                      <div style={{ fontSize: "0.7rem", marginBottom: "4px" }}>Add a short line of text to the bottom of your message template.</div>
                      <Styles.StyledTextField
                        borderStyle
                        disabled={_.isEmpty(selectedMessageTemplate)}
                        name={`messageTemplates[${values.selectingIndex}].footer.text`}
                        maxLength={60}
                      />
                    </Styles.Card>

                    <Styles.Card>
                      <Styles.HeadingContainer>
                        <Styles.TextWrapper>
                          <div style={{ fontWeight: "bold", padding: "4px 4px 4px 0" }}>Buttons</div>
                          <Styles.OptionalLabel>Optional</Styles.OptionalLabel>
                        </Styles.TextWrapper>
                        <SampleButton
                          text="Add Sample"
                          onClick={() => {
                            setModalContent("button")
                            setOpenModal(true)
                          }}
                          isDisabled={disableButtonSampleButton({ messageTemplate: selectedMessageTemplate })}
                        />
                      </Styles.HeadingContainer>
                      <div style={{ fontSize: "0.7rem", margin: "8px 0 4px 0" }}>Create buttons that let customers respond to your message or take action.</div>
                      <Select
                        value={buttonsTypeOptions.find(o => o.value === values.selectedButtonType) || null}
                        options={buttonsTypeOptions}
                        isDisabled={_.isEmpty(selectedMessageTemplate)}
                        onChange={(option) => {
                          change("selectedButtonType", option.value)
                          let newButtons = null
                          if (option.value === "Quick Reply") {
                            newButtons = [{
                              tempId: randomString(8),
                              type: "QUICK_REPLY",
                              text: ""
                            }]
                          } else if (option.value === "Call To Action") {
                            newButtons = [{
                              tempId: randomString(8),
                              type: "PHONE_NUMBER",
                              text: "",
                              phone_number: ""
                            }]
                          }
                          const clone = _.cloneDeep(values.messageTemplates)
                          if (newButtons) {
                            _.set(clone, `[${values.selectingIndex}].buttons`, newButtons)
                          } else {
                            _.unset(clone, `[${values.selectingIndex}].buttons`)
                          }
                          change("messageTemplates", clone)
                        }}
                      />
                      <FieldArray
                        name={`messageTemplates[${values.selectingIndex}].buttons`}
                      >
                        {({ fields }) => {
                          let buttonLimit = 0
                          if (values.selectedButtonType === "Quick Reply") {
                            buttonLimit = 3
                          } else if (values.selectedButtonType === "Call To Action") {
                            buttonLimit = 2
                          }

                          return (
                            <Fragment>
                              {fields.map((name, index) => renderButton({
                                fields,
                                button: fields.value[index],
                                buttons,
                                index,
                                change,
                                remove: fields.remove,
                                canRemove: buttons && buttons.length !== 1,
                                arrayPath: `messageTemplates[${values.selectingIndex}].buttons`,
                                path: `messageTemplates[${values.selectingIndex}].buttons[${index}]`
                              }))}
                              {values.selectedButtonType && values.selectedButtonType !== "None" ?
                                <NewBoxButton
                                  style={{ fontSize: "1rem" }}
                                  text="Add Button"
                                  type="button"
                                  primary
                                  invert
                                  disabled={_.get(selectedMessageTemplate, "buttons.length", 0) >= buttonLimit}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    if (values.selectedButtonType === "Quick Reply") {
                                      push(`messageTemplates[${values.selectingIndex}].buttons`, {
                                        tempId: randomString(8),
                                        type: "QUICK_REPLY",
                                        text: ""
                                      })
                                    } else if (values.selectedButtonType === "Call To Action") {
                                      if (_.get(selectedMessageTemplate, "buttons", []).find(o => o.type === "PHONE_NUMBER")) {
                                        push(`messageTemplates[${values.selectingIndex}].buttons`, {
                                          tempId: randomString(8),
                                          type: "URL",
                                          text: "",
                                          url: ""
                                        })
                                      } else {
                                        push(`messageTemplates[${values.selectingIndex}].buttons`, {
                                          tempId: randomString(8),
                                          type: "PHONE_NUMBER",
                                          text: "",
                                          phone_number: ""
                                        })
                                      }
                                    }
                                  }}
                                />
                                :
                                null
                              }
                            </Fragment>
                          )
                        }}
                      </FieldArray>
                      {/* {buttons && buttons.type === "Quick Reply" ?
                        quickReplyButtons(buttons.type, buttons.buttons)
                        :
                        null
                      } */}
                    </Styles.Card>
                    <Modal
                      open={openModal}
                    >
                      {({ modalRef }) => (
                        <WhatsappMessageTemplateSampleModal
                          index={values.selectingIndex}
                          values={values}
                          change={change}
                          selectedMessageTemplate={selectedMessageTemplate}
                          modalContent={modalContent}
                          onClose={() => setOpenModal(false)}
                          setFile={setFile}
                          file={file}
                          info={info}
                        />
                      )}
                    </Modal>
                  </Styles.MiddleSection>
                  <Styles.RightSection>
                    <Styles.WhatsappBackgroundImage>
                      <div style={{ fontWeight: "bold", padding: "4px 4px 4px 0" }}>Preview</div>
                      <Styles.ChatContainer>
                        <WhatsappChatBubble
                          fullWidth={_.get(buttons, "length")}
                          buttons={values.selectedButtonType === "Call To Action" ?
                            renderButtonsBubble(buttons, values.selectedButtonType)
                            :
                            null
                          }
                        >
                          {renderHeader(header, selectedHeaderMediaType)}
                          {body && body.text ?
                            <div style={{ fontSize: "0.7rem", wordBreak: "break-word"}}>{body.text}</div>
                            :
                            null
                          }
                          {footer && footer.text ?
                            <div style={{ fontSize: "0.65rem", color: "#979797", wordBreak: "break-word" }}>{footer.text}</div>
                            :
                            null
                          }
                        </WhatsappChatBubble>
                        {values.selectedButtonType === "Quick Reply" ?
                          renderButtonsBubble(buttons, values.selectedButtonType)
                          :
                          null
                        }
                      </Styles.ChatContainer>
                    </Styles.WhatsappBackgroundImage>
                  </Styles.RightSection>
                </Styles.SectionContainer>
              </Styles.MessageTemplateContent>
            </Styles.StyledForm>
          )
        }}
      </Form>
    </div>
  )
}

export default CreateMessageTemplate

