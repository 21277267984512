import React, { useState, Fragment } from "react"
import { useField } from "react-final-form"
import Select from "react-select"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'


import { randomString } from "../../util"
import {
  Container,
  Label,
  StyledTooltip,
  Error,
} from "./FormSelectFieldStyles"

const FormSelectField = ({
  label,
  tooltipText,
  tooltipOptions,
  className,
  options,
  offsetLabel = false,
  disabled,
  ...props
}) => {
  const { input, meta } = useField(props.name)
  const [randomId] = useState(randomString(10))
  const [focused, setFocused] = useState(false)

  const hasError = meta.error && meta.touched
  const hasLongError = meta.error && meta.error.length >= 40
  const hasLabel = !!label

  const selectedOption = options.find(o => o.value === input.value) || null
  return (
    <Container
      className={className}
      focused={focused}
      disabled={disabled}
      hasError={hasError}
      hasLongError={hasLongError}
      offsetLabel={offsetLabel}
    >
      {label ?
        <Label
          className="label"
          focused={focused}
          hasError={hasError}
        >
          <span>{label}</span>
          {tooltipText ?
            <Fragment>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="tooltip-icon"
                data-tip={tooltipText}
                data-for={randomId}
              />
              <StyledTooltip
                offset={{ top: -6 }}
                id={randomId}
                effect="solid"
                {...tooltipOptions}
              />
            </Fragment>
            :
            null
          }
          {hasLongError && hasError ?
            <Fragment>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="tooltip-icon select-field-tooltip-icon"
                data-tip={meta.error}
                data-for={`${randomId}-error`}
              />
              <StyledTooltip
                offset={{ top: -6 }}
                id={`${randomId}-error`}
                effect="solid"
                type="error"
                multiline
                {...tooltipOptions}
              />
            </Fragment>
            :
            null
          }
        </Label>
        :
        null
      }

      <div className="content">
        <Select
          {...input}
          {...props}
          options={options}
          isDisabled={disabled}
          value={selectedOption}
          onChange={(option) => {
            if (props.onChange) {
              props.onChange(option.value, option)
            } else {
              input.onChange(option.value)
            }
          }}
          onFocus={() => {
            if (props.onFocus) props.onFocus()
            input.onFocus()
            setFocused(true)
          }}
          onBlur={() => {
            if (props.onBlur) props.onBlur()
            input.onBlur()
            setFocused(false)
          }}
        />
      </div>
      {hasError && !hasLongError ?
        <Error
          className="error-container"
          hasLabel={hasLabel}
        >
          {meta.error}
        </Error>
        :
        null
      }
    </Container>
  )
}

export default FormSelectField
