import React, { Component } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import styled from "styled-components"

const propTypes = {
  digit: PropTypes.number.isRequired,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  masked: PropTypes.bool
}

const defaultProps = {
  defaultValue: "",
  onChange: () => { },
  disabled: false,
  masked: false
}

const InputWrapper = styled.div`
  padding: 8px 0;
  align-items: center;
`

const VertifyCodeInput = styled.input`
  width: 24px;
  height: 36px;
  border-radius: 6px;
  margin: 0 4px;
  text-align: center;
  border: 1px solid ${props => props.theme.gray2};
  background: ${props => (props.disabled ? props.theme.gray1 : "#ffffff")};
  font-size: ${props => (props.masked ? "48px" : "16px")};
  color: ${props => (props.masked ? props.theme.gray6 : props.theme.gray7)};
`

class PinInput extends Component {
  constructor(props) {
    super(props)
    const {
      digit,
      defaultValue,
      masked,
    } = props
    let initialCodes = new Array(digit).fill(masked ? "•" : "")
    if (defaultValue) {
      const split = defaultValue.split("")
      if (split.length < digit) {
        initialCodes = [...split, ...new Array(digit - split.length).fill(masked ? "•" : "")]
      } else if (split.length > digit) {
        initialCodes = split.slice(0, digit)
      } else {
        initialCodes = split
      }
    }

    this.state = {
      codes: initialCodes
    }
    this.reset = this.reset.bind(this)
    const map = []
    for (let index = 0; index < digit; index++) {
      map.push(React.createRef())
    }
    this.refMap = map
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState.codes, this.state.codes)) {
      this.props.onChange(this.state.codes.join(""))
    }
    if (prevProps.masked !== this.props.masked) {
      this.setState({
        codes: new Array(this.props.digit).fill(this.props.masked ? "•" : "")
      })
    }
  }

  reset() {
    const { masked } = this.props
    this.setState({
      codes: new Array(this.props.digit).fill(masked ? "•" : "")
    })
  }

  render() {
    const { codes } = this.state
    return (
      <InputWrapper className="input-wrapper">
        {this.props.label && <span sx={{ marginBottom: "8px", color: "#777777", fontSize: "0.83333rem" }}>{this.props.label}</span>}
        {codes.map((code, i) => (
          <VertifyCodeInput
            key={i}
            className="vertify-code-input"
            value={code}
            disabled={this.props.disabled}
            masked={this.props.masked}
            ref={this.refMap[i]}
            onChange={() => {}}
            onKeyUp={(e) => {
                const clone = _.clone(codes)
                if (e.key === "Backspace") {
                  clone[i] = ""
                  if (i - 1 >= 0) {
                    this.refMap[i - 1].current.focus()
                  }
                } else if (/\d/.test(e.key)) {
                  clone[i] = e.key
                  if (i + 1 < codes.length) {
                    this.refMap[i + 1].current.focus()
                  }
                }
                this.setState({
                  codes: clone
                })
              }}
          />
          ))}
      </InputWrapper>
    )
  }
}

PinInput.propTypes = propTypes
PinInput.defaultProps = defaultProps

export default PinInput
