/* eslint-disable react/no-array-index-key */
import React, { Fragment } from "react"
import styled from "styled-components"
import _ from "lodash"

const TextField = styled.input`
  font-size: 1em;
  color: #626262;
  outline: none;
  margin-bottom: 16px;
  margin-top: 2px;
  /* margin-left: 4px; */
  margin-right: 4px;
  padding: 6px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  overflow: hidden;
  overflow-wrap: break-word;
  width: 100%;
  box-sizing: border-box;
`

const WhatsappMessageTemplateSampleBodyContent = ({
  index,
  values,
  selectedMT,
  ...props
}) => {
  const sampleValueArray = _.get(selectedMT, "body.example.body_text[0]", [])

  const bodyText = _.get(values, `messageTemplates[${index}].body.text`, "")
  const curlyBracketArray = bodyText.match(/{{([1-9][0-9]*)}}/g)

  return (
    <Fragment>
      {
        curlyBracketArray.map((cb, cbIndex) => (
          <TextField
            key={cbIndex}
            label={cb}
            value={sampleValueArray[cbIndex] || ""}
            onChange={(e) => {
                const clonedSampleContent = _.cloneDeep(selectedMT)
                _.set(clonedSampleContent, `body.example.body_text[0][${cbIndex}]`, e.target.value)
                props.onChange(clonedSampleContent)
              }}
          />
          ))
      }
    </Fragment>
  )
}

export default WhatsappMessageTemplateSampleBodyContent
