import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  user-select: none;
  border-radius: 4px;
  text-align: center;
  margin-left: auto;
`

const Button = styled.button`
  background-color: ${props => (props.disabled ? props.theme.gray2 : props.theme.gray05)};
  padding: 9px 16px;
  border-radius: 4px;
  border: 1px solid transparent;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
`

const SampleButton = ({
  onClick,
  fileUpload,
  isDisabled,
  text,
}) => (
  <Wrapper onClick={onClick}>
    <Button
      disabled={isDisabled}
      type="button"
    >
      {text}
    </Button>
  </Wrapper>
)

export default SampleButton
