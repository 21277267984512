import React, { Component } from "react"
import CreateMessageTemplate from "../CreateMessageTemplate/CreateMessageTemplate"
import ListMessageTemplates from "../ListMessageTemplates/ListMessageTemplates"
import * as Styles from "./MessageTemplateStyles"

class MessageTemplate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      createPage: false,
    }
  }

  render() {
    return (
      <Styles.MessageTemplateContainer createPage={this.state.page === "create"} style={{ height: "100%", boxSizing: "border-box" }}>
        {this.state.page === "create" ?
          <CreateMessageTemplate 
            payload={this.props.payload}
            signedContext={this.props.signedContext}
            info={this.props.info}
            onChangePage={(value) => {
              this.setState({
                page: value
              })
            }}
          />
          :
          <ListMessageTemplates 
            payload={this.props.payload}
            signedContext={this.props.signedContext}
            onChangePage={(value) => {
              this.setState({
                page: value
              })
            }}
          />
        }
      </Styles.MessageTemplateContainer>
    )
  }
}

export default MessageTemplate
