/* eslint-disable no-template-curly-in-string */
import { string, object, array } from "yup"

export const whatsappMessageTemplateSchema = object().shape({
  name: string().test(
    "name",
    "Your ${path} must only contain lowercase alphanumeric characters and underscores ( _ ). No other characters or white space are allowed.",
    value => /^[a-z0-9_]+$/g.test(value)
  ).required("Name is required"),
  category: string().oneOf([
    "ACCOUNT_UPDATE",
    "PAYMENT_UPDATE",
    "PERSONAL_FINANCE_UPDATE",
    "SHIPPING_UPDATE",
    "RESERVATION_UPDATE",
    "ISSUE_RESOLUTION",
    "APPOINTMENT_UPDATE",
    "TRANSPORTATION_UPDATE",
    "TICKET_UPDATE",
    "ALERT_UPDATE",
    "AUTO_REPLY"
  ]).required("Category is required"),
  messageTemplates: array().min(1, "At least 1 language should be added.").of(object().shape({
    language: string().required("Language is required"),
    header: object().shape({
      text: string().when("format", (format, schema) => {
        if (format === "text") {
          return schema.required("Header text is required")
            .test(
              "header-text",
              "Header must not contain any emojis.",
              value => !/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g.test(value)
            )
            .test(
              "header-text",
              "Variable must start with {{1}}", (value) => {
                const regex = new RegExp("{{([1-9][0-9]*)}}")
                if (regex.test(value)) {
                  if (!value.includes("{{1}}")) return false
                }
                return true
              }
            )
        }
        return schema.strip()
      }),
      format: string().oneOf([
        "media",
        "text"
      ]).required(),
      mediaType: string().when("format", (format, schema) => {
        if (format === "media") {
          return schema.oneOf([
            "image",
            "video",
            "document",
          ]).required("You must select a media type")
        }
        return schema.strip()
      })
    }).default({
      format: "text",
      text: "placeholder"
    }),
    body: object().shape({
      text: string()
        .required("Body text is required")
        .test("body-text", "Variable must start with {{1}}", (value) => {
          const regex = new RegExp("{{([1-9][0-9]*)}}")
          if (regex.test(value)) {
            if (!value.includes("{{1}}")) return false
          }
          return true
        })
    }).default({}),
    footer: object().shape({
      text: string().test(
        "footer-text",
        "Footer must not contain any emojis.",
        value => !/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g.test(value)
      )
    }).default({
      text: "placeholder"
    }),
    buttons: array().of(object().shape({
      text: string().test(
        "button-text",
        "Button must not contain any emojis.",
        value => !/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g.test(value)
      ).required("Button text is required"),
      type: string().required("You must select a type").oneOf([
        "QUICK_REPLY",
        "PHONE_NUMBER",
        "URL"
      ]),
      url: string().matches(/^https?:\/\//, "URL must start with http or https").when("type", (type, schema) => {
        if (type === "URL") {
          return schema.required("URL is required.")
        }
        return schema.strip()
      }),
      phone_number: string().when("type", (type, schema) => {
        if (type === "PHONE_NUMBER") {
          return schema.required("Phone number is required")
        }
        return schema.strip()
      })
    }))
  }))
})
