import styled, { css } from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Select from "react-select"
import ReactTooltip from "react-tooltip"

import FormSelectField from "../Radiate/FormSelectField/FormSelectField"
import TextField from "../Radiate/TextField/TextField"
import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"

export const StyledForm = styled.form`
  color: ${props => props.theme.gray6};
  border-radius: 4px;
  overflow: hidden;
  height: 100%;

  .top-bar {
    background-color: ${props => props.theme.themeColorDark};
    height: 59px;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      padding-left: 16px;
      color: #ffffff;
      font-size: ${props => props.theme.textMd};
      cursor: pointer;
    }

    .icon {
      margin: 0 16px;
      cursor: pointer;
    }
  }

  .tooltip-icon,
  .header-text-tooltip-icon,
  .body-tooltip-icon {
    color: ${props => props.theme.gray7};
  }

  .header-text-tooltip-icon,
  .body-tooltip-icon {
    font-size: 0.7rem;
  }
`
export const OptionalLabel = styled.div`
  display: inline-block;
  background-color: ${props => props.theme.superPalerBackground};
  padding: 4px;
  font-size: 0.7rem;
  font-weight: bold;
  border-radius: 4px;
  margin: auto 0;
`


export const StyledErrorMessage = styled.div`
  color: ${props => props.theme.error};
  font-size: 0.7rem;
`

export const CategoryTextField = styled.input`
  flex-grow: 1;
  font-size: 1em;
  color: #626262;
  outline: none;
  margin-bottom: 3px;
  margin-top: 2px;
  margin-right: 4px;
  padding: 6px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  overflow: hidden;
  overflow-wrap: break-word;
  width: 100%;
  box-sizing: border-box;
`
export const Form = styled.input`
  font-size: 1em;
  color: #626262;
  outline: none;
  padding: 8px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  overflow: hidden;
  overflow-wrap: break-word;
  box-sizing: border-box;
  .place-top > .multi-line {
    text-align: left;
  }
`
export const CategorySelectField = styled(FormSelectField)`
  flex-shrink: 0;
  width: 300px;
  margin-left: 16px;
`

export const StyledSelectField = styled(FormSelectField)`
  width: 300px;
`

export const MessageTemplateContent = styled.div`
  overflow-y: scroll;
  height: calc(100% - 111px);
  box-sizing: border-box;
  /* background-color: ${props => props.theme.lightButtonBg}; */
  margin-top: 16px;
`

export const Card = styled.div`
  background-color: #ffffff;
  border: 1px solid ${props => props.theme.gray1};
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 16px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.06);
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`

export const NameField = styled.input`
  width: 60%;

  .radiate-theme.text-field-container .content {
    margin-left: 0;
  }
`

export const ErrorMessageList = styled.div`
  padding: 8px 16px;
  color: ${props => props.theme.error};
  font-size: 0.7rem;
  background: rgba(217,57,32,0.05);
  border-radius: 4px;
  white-space: pre-line;
`

export const CategoryField = styled.div`
  width: 30%;
  margin-left: 32px;

  .label {
    font-size: 0.8rem;
    margin-bottom: 12px;
  }
`

export const SectionContainer = styled.div`
  display: flex;
  margin-bottom: 12px;
`

export const HeadingContainer = styled.div`
  display: flex;
  align-items: center;

  .header-text-tooltip-icon, .body-tooltip-icon {
    color: ${props => props.theme.gray5};
  }
`

export const StyledTooltip = styled(ReactTooltip)`
  padding: 6px 8px !important;
  max-width: 400px;

  &:before, &:after {
    border-width: 0px !important;
  }
`

export const LeftSection = styled.div`
  width: 25%;
`

export const MiddleSection = styled.div`
  width: 48%;
  margin: 0px 1%;
`

export const InputContainer = styled.div`
  width: 100%;
`

export const InputTextField = styled.input`
  &&&& > .content {
    margin-left: 0;
  }
`

export const Header = styled.div`
  width: 100%;
`

export const Body = styled.div`
  width: 100%;
`

export const Footer = styled.div`
  width: 100%;
`

export const RightSection = styled.div`
  width: 25%;
`

export const LanguageContainer = styled.div`
  width: 100%;

  .language-selector {
    padding: 8px 0;
  }
`

export const Language = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.7rem;
  padding: 8px;
  cursor: pointer;

  ${props => props.selected && css`
    font-weight: bold;
    color: ${props.theme.selectorFocus};
    background-color: ${props.theme.themeColorLightFocus};
  `}
`

export const IconContainer = styled.div`
  max-width: 450px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledSelector = styled(Select)`
  margin-bottom: 16px;
`

export const MediaIconContainer = styled.div`
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  width: 30%
  color: ${props => (props.active ? props.theme.selectorFocus : props.theme.gray1)};
  margin-bottom: 16px;

  & > div {
    color: ${props => (props.active ? props.theme.selectorFocus : props.theme.gray3)};
  }
`

export const MediaIcon = styled(FontAwesomeIcon)`
  padding: 16px;

  >svg {
    width: 20px;
    height: 20px;
  }
  
`

export const PreviewIconContainer = styled.div`
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.gray2};
  border-radius: 4px;
  margin-bottom: ${props => props.theme.spaceXs};
`

export const WhatsappBackgroundImage = styled.div`
  background-image: url("/img/whatsapp-chat-background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  padding: 8px;
`

export const BottomBar = styled.div`
  box-sizing: border-box;
  height: 52px;
  display: flex;
  justify-content: flex-end;
  padding: 8px 4px;
  box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.lightButtonBg};
  border-top: 1px solid ${props => props.theme.gray1};
  align-items: center;

  .bottom-bar-button {
    margin: 4px 8px;
    width: 80px;
    height: 32px;
  }
`

export const SubmitButton = styled(NewBoxButton)`
  font-size: 1rem;

  .loading-icon {
    color: #ffffff;
  }
`

export const QuickReplyButton = styled.div`
  padding: 8px 16px 0 16px;
  position: relative;
  border-radius: 4px;
  margin: 16px 0;
  border: 1px solid ${props => props.theme.gray1};
`

export const OutsideButtonsContainer = styled.div`
  margin-left: 12px;
`
export const InnerButtonsContainer = styled.div`
  width: 100%;
`

export const Separator = styled.div`
  height: 1px;
  background: ${props => props.theme.gray2};
  margin: auto;
  width: calc(100% - 16px);
`

export const WhatsappButton = styled.div`
  height: 34px;
  display: flex;
  justify-content: center;
  background: white;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, .15);
  border-radius: 5px;
  align-items: center;
  margin: 2px 0;
  color: ${props => props.theme.themeColorHighlight};
`
export const CTAWhatsappButton = styled.div`
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  color: ${props => props.theme.themeColorHighlight};

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`

export const ChatContainer = styled.div`
  max-width: 75%;
  margin: 8px 0;
`

export const TextButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const TextWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`


export const StyledTextField = styled(TextField)`
  width: ${props => props.width ? props.width : "100%"};
  
  .place-top > .multi-line {
    text-align: left;
  }
`
