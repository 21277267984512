import styled, { css } from "styled-components"
import ReactTooltip from "react-tooltip"

export const StyledTooltip = styled(ReactTooltip)`
  padding: 8px;

   &:before, &:after {
    border-width: 0px !important;
  }
`

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.flexEnd ? "flex-end": "space-between"};
  font-size: 0.7rem;
  margin-bottom: 2px;

  >.length-count {
    color: ${(props) => {
    if (props.hasError) {
      return props.theme.dangerText
    }
    if (props.focused && !props.disabled) {
      return props.theme.themeColor
    }
    return props.theme.gray4
  }};
  }
`

export const Container = styled.div`
  padding: ${props => props.JFStyle ? "0" : "4px 0 16px 0" };
  display: ${props => props.isHidden ? "none" : "auto"};
  
  ${props => props.hasError && !props.hasLongError && !props.hasLengthCounter && css`
    padding-bottom: 2px;
  `}

  >.content {
    background: ${props => props.disabled && props.borderStyle ? "#f2f2f2" : "#ffffff"};
    margin-left: ${props => (props.offsetLabel ? "12px" : "0")};
    display: flex;
    align-items: center;
    pointer-events: ${props => props.disabled ? "none" : "auto"};
    border-bottom: 2px solid ${(props) => {
      if (!props.borderStyle) {
        if (props.hasError) {
          return props.theme.dangerText
        }
        if (props.focused) {
          return props.theme.themeColor
        }
        return props.theme.borderColorDark
      }
    }};
    min-height: ${(props) => {
      if (props.borderStyle === "L") {
        return "48px"
      } else if (props.borderStyle === "M") {
        return "38px"
      } else if (props.borderStyle === "S") {
        return "28px"
      }
      return "36px"
    }};
    border-radius: ${props => (props.borderStyle ? "4px" : "0px")};
    box-sizing: border-box;
    font-size: ${(props) => {
      if (props.borderStyle === "L") {
        return "1.3em"
      } else if (props.borderStyle === "M") {
        return "1em"
      } else if (props.borderStyle === "S") {
        return "0.9em"
      }
    }};
    border: ${(props) => {
      if (props.borderStyle) {
        if (props.hasError) {
          return `1px solid ${props.theme.dangerText}`
        }
        if (props.focused && !props.disabled) {
          return `1px solid ${props.theme.themeColor}`
        }
        return `1px solid ${props.theme.borderColorDark}`
      }
    }};
    padding: ${props => props.borderStyle && "4px"};

    textarea {
      border: none;
      width: 100%;
      resize: none;
      font-size: 1em;
      background: transparent;
      color: ${props => props.theme.gray7};
      outline: none;
      font-family: sans-serif;

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: ${props => props.theme.placeholderText};
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: ${props => props.theme.placeholderText};
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: ${props => props.theme.placeholderText};
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: ${props => props.theme.placeholderText};
      }
    }

    input {
      border: none;
      width: 100%;
      font-size: 1em;
      background: transparent;
      color: ${props => props.theme.gray7};
      outline: none;
      margin-bottom: 3px;
      margin-top: 2px;
      border-style: none;

      &.disabled-input {
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      &:-internal-autofill-selected {
        background-color: #ffffff;
      }
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: ${props => props.theme.placeholderText};
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: ${props => props.theme.placeholderText};
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: ${props => props.theme.placeholderText};
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: ${props => props.theme.placeholderText};
      }
    }
  }
`

export const Label = styled.div`
  margin-bottom: 2px;
  color: ${(props) => {
    if (props.hasError) {
      return props.theme.dangerText
    }
    if (props.focused && !props.disabled) {
      return props.theme.themeColor
    }
    return props.theme.gray7
  }};

  span {
    margin-right: 4px;
  }

  .tooltip-icon.text-field-tooltip-icon {
    font-size: 12px;
    color: ${(props) => {
    if (props.hasError) {
      return props.theme.dangerText
    }
    if (props.focused && !props.disabled) {
      return props.theme.themeColor
    }
    return props.theme.gray5
  }};
  }
`

export const Error = styled.div`
  color: ${props => props.theme.dangerText};
  font-size: 0.7rem;
`

export const LongErrorIconWrapper = styled.span`
  display: ${props => (props.show ? "all" : "none")};
`

export const BottomRow = styled.div`
  margin-top: 2px;
  margin-left: ${props => (props.hasLabel && props.offsetLabel ? "12px" : "0")};
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
