import React from "react"
import PropsTypes from "prop-types"
import styled from "styled-components"

const propsTypes = {
  children: PropsTypes.node,
  style: PropsTypes.string,
  fullWidth: PropsTypes.bool,
}

const defaultProps = {
  style: "left",
  fullWidth: false,
}

const Bubble = styled.div`
  display: inline-block;
  min-width: 100px;
  width: ${props => (props.fullWidth ? "calc(100% - 12px)" : "auto")};
  position: relative;
  background-color: ${props => (props.direction === "right" ? "#DCF8C6" : "#fff")};
  border-radius: 5px;
  margin: ${props => (props.direction === "right" ? "0 12px 0 0px" : "0 0 0 12px")};   
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, .15);
`

const Content = styled.div`
  padding: 8px 8px 20px 8px;
  width: 100%;
  position: relative;
  min-height: 34px;
  display: inline-block;
  box-sizing: border-box;
`

const Timestamp = styled.span`
  font-size: 10px;
  margin: 0 8px 4px auto;
  color: #999;
  position: absolute;
  right: 0;
  bottom: 0;
`

const Text = styled.span`
  display: inline-block;
  word-break: break-all;
  white-space: pre-wrap;
  min-height: 16px;
  width: 100%;
`

const Arrow = styled.div`
  position: absolute;
  float: ${props => (props.direction === "right" ? "right" : "left")};
  left: ${props => (props.direction === "right" ? "auto" : "-11px")};
  top: 0px;
  right: ${props => (props.direction === "right" ? "4px" : "")};
  bottom: ${props => (props.direction === "right" ? "20px" : "")};

  &:after {
    content: "";
    position: absolute;
    border-top: ${props => (props.direction === "right" ? "15px solid #DCF8C6" : "15px solid #fff")};
    border-left: 15px solid transparent;           
    border-radius: 4px 0 0 0px;
    width: 0;
    height: 0;
    transform: ${props => (props.direction === "right" ? "scaleX(-1)" : "")};
  }
`

const WhatsappChatBubble = ({
  children, direction, fullWidth, buttons
}) => (
  <Bubble
    direction={direction}
    fullWidth={fullWidth}
  >
    <Content>
      <Text>{children}</Text>
      <Timestamp>10:20 AM</Timestamp>
    </Content>
    {buttons}
    <Arrow direction={direction} />
  </Bubble>
)

WhatsappChatBubble.propsTypes = propsTypes
WhatsappChatBubble.defaultProps = defaultProps

export default WhatsappChatBubble
