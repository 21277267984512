import styled, { css } from "styled-components"
import ReactTooltip from "react-tooltip"

export const StyledTooltip = styled(ReactTooltip)`
  padding: 6px 8px !important;

  &:before, &:after {
    border-width: 0px !important;
  }
`

export const Container = styled.div`
  padding: 4px 0 16px 0;

  ${props => props.hasError && !props.hasLongError && css`
    padding-bottom: 0;
  `}

  .content {
    margin-left: ${props => (props.offsetLabel ? "12px" : "0")};
    textarea {
      border: none;
      width: 100%;
      resize: none;
      font-size: 1em;
      background: transparent;
      color: ${props => props.theme.gray10};
      outline: none;

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: ${props => props.theme.placeholderText};
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: ${props => props.theme.placeholderText};
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: ${props => props.theme.placeholderText};
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: ${props => props.theme.placeholderText};
      }
    }

    input {
      border: none;
      width: 100%;
      font-size: 1em;
      background: transparent;
      color: ${props => props.theme.gray10};
      outline: none;
      margin-bottom: 3px;
      margin-top: 2px;

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: ${props => props.theme.placeholderText};
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: ${props => props.theme.placeholderText};
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: ${props => props.theme.placeholderText};
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: ${props => props.theme.placeholderText};
      }
    }
  }
`

export const Label = styled.div`
  color: ${(props) => {
    if (props.hasError) {
      return props.theme.dangerText
    }
    if (props.focused) {
      return props.theme.themeColor
    }
    return props.theme.darkButtonText
  }};
  font-size: 0.7rem;
  margin-bottom: 4px;

  span {
    margin-right: 4px;
  }

  .tooltip-icon.select-field-tooltip-icon {
    color: ${(props) => {
    if (props.hasError) {
      return props.theme.dangerText
    }
    if (props.focused) {
      return props.theme.themeColor
    }
    return props.theme.darkButtonText
  }};
  }
`

export const Error = styled.div`
  margin-left: ${props => (props.hasLabel ? "12px" : "0")};
  margin-top: 2px;
  color: ${props => props.theme.dangerText};
  font-size: 0.7rem;
`
