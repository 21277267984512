/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import React, { Component } from "react"
import { Helmet } from "react-helmet"
import Select from "react-select"
import _ from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCamera, faCheckCircle, faCircleNotch, faExclamationCircle, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import NewBoxButton from "../../components/Radiate/NewBoxButton/NewBoxButton"
import SetUp from "../../components/SetUp/SetUp"
import MessageTemplate from "../../components/MessageTemplate/MessageTemplate"
import AccessTokenManager from "../../components/AccessTokenManager/AccessTokenManager"
import Tab from "../../components/Radiate/Tab/Tab"

import * as Styles from "./HomeStyles"
import { categoryOptions } from "../../components/util"

const SERVER_API_PATH = process.env.REACT_APP_SERVER_API_PATH
const DOMAIN = process.env.REACT_APP_WOZTELL_URL

export function randomString(length) {
  const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ"
  let result = ""
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)]
  }
  return result
}
class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      defaultApp: true,
      info: {},
      loading: false,
      channelObj: null,
      savingError: null,
      profileData: {},
      originalProfileData: {},
      loadingProfile: false,
      updatingProfile: false,
      updateSuccess: false,
      updateErrorMessage: "",
      uploadProfilePicStatus: "",
      unsubscribe: false,
      resubscribing: false,
    }
    this.receiveMessage = this.receiveMessage.bind(this)
    this.updateWhatsappProfile = this.updateWhatsappProfile.bind(this)
    this.resumableUpload = this.resumableUpload.bind(this)
  }

  componentDidMount() {
    if (navigator.userAgent !== "ReactSnap") {
      window.addEventListener("message", this.receiveMessage, false)
      this.getDataFromDb()
      window.parent.postMessage("iframeFinishLoading", DOMAIN)
      window.parent.postMessage("iframeloading", DOMAIN)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState.channelObj, this.state.channelObj) && this.state.channelObj) {
      this.getDataFromDb()
    }
    if (!_.isEqual(prevState.info, this.state.info) && this.state.info?.phoneNumberId) {
      this.getWhatsAppProfile(true)
    }
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.receiveMessage, false)
  }

  receiveMessage(event) {
    if (event.origin !== DOMAIN) {
      return
    }
    if (event?.data) {
      if (event?.data?.unsubscribe) {
        this.setState({
          unsubscribe: true
        })
      } else {
        this.setState({
          channelObj: event.data
        })
      }
    }
  }

  async getWhatsAppProfile(withLoading) {
    if (withLoading) {
      this.setState({
        loadingProfile: true
      })
    }
    const result = await fetch(`${SERVER_API_PATH}/get-whatsapp-business-profile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ 
        phoneNumberId: this.state.info?.phoneNumberId,
        payload: this.state.channelObj?.payload,
        signedContext: this.state.channelObj?.signedContext,
      }),
    }).then(res => res.json())
    this.setState({
      loadingProfile: false
    })
    if (result.ok === 0) {
      console.error(result.error)
    } else {
      this.setState({
        profileData: result.data || {},
        originalProfileData: result.data || {},
      })
    }
  }

  async updateWhatsappProfile(data) {
    this.setState({
      updatingProfile: true,
      updateSuccess: false
    })
    const values = _.cloneDeep(data)
    if (values) {
      values.messaging_product = "whatsapp"
    }
    const result = await fetch(`${SERVER_API_PATH}/update-whatsapp-business-profile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ 
        payload: this.state.channelObj?.payload,
        signedContext: this.state.channelObj?.signedContext,
        profile: values, 
        phoneNumberId: this.state.info?.phoneNumberId
      }),
    }).then(res => res.json())
    this.setState({
      updatingProfile: false,
    })
    if (result.ok === 0) {
      this.setState({
        updateErrorMessage: result.error
      })
    } else {
      this.setState({
        updateSuccess: true,
        fileName: "",
        updateErrorMessage: ""
      }, () => {
        this.getWhatsAppProfile()
      })
    }
  }

  async getDataFromDb() {
    this.setState({
      loading: true
    })
    if (this.state.channelObj) {
      const result = await fetch(`${SERVER_API_PATH}/get-whatsapp-cloud-info`, {
        method: "POST",
        body: JSON.stringify(this.state.channelObj),
        headers: {
          "Content-Type": "application/json"
        }
      }).then(res => res.json())
      if (result.ok) {
        this.setState({
          info: result?.data?.info,
          unsubscribe: result?.data?.unsubscribe,
          loading: false
        })
      } else {
        this.setState({
          loading: false
        })
      }
      // window.parent.postMessage("iframeFinishLoading", DOMAIN)
    }
  }

  async resumableUpload(files) {
    this.setState({
      uploadProfilePicStatus: "loading"
    })
    const uploadedFile = files[0]
    // console.log("uploadedFile", uploadedFile)
    if (!uploadedFile.type.includes("jpeg") && !uploadedFile.type.includes("jpg") && !uploadedFile.type.includes("png")) {
      // setUploadStatus("failure")
      this.setState({
        uploadProfilePicStatus: "failure"
      })
      return alert("Unsupported image format")
    }

    const params = {
      fileName: uploadedFile.name,
      fileType: uploadedFile.type,
      phoneNumber: this.state.info?.phoneNumber,
      phoneNumberId: this.state.info?.phoneNumberId,
      tags: [{
        key: "Type",
        value: "MediaFileForProfilePic"
      }]
    }

    const json = await fetch(`${SERVER_API_PATH}/sign-url`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(response => response.json())
    const form = new FormData()
    if (json.ok) {
      _.forEach(json?.data?.fields, (value, key) => {
        form.append(key, value)
      })
      form.append("file", uploadedFile)
      const uploadToS3Response = await fetch(json.data.url, { 
        method: "POST", 
        body: form,
      })
      console.log("uploadToS3Response", uploadToS3Response)
      if (uploadToS3Response.status === 204) {
        const split = json?.data?.fields.key.split("/")
        const encodedSplit = split.map(s => encodeURIComponent(s))
        const fileUrl = `${json?.data?.url}/${encodedSplit.join("/")}`
        try {
          const upload = await fetch(`${SERVER_API_PATH}/resumable-upload`, {
            method: "POST",
            body: JSON.stringify({
              url: fileUrl
            }),
            headers: {
              "Content-Type": "application/json"
            }
          }).then(response => response.json())
          this.setState({
            uploadProfilePicStatus: "success",
            filename: split[split.length - 1],
            profileData: {
              ...this.state.profileData,
              profile_picture_handle: upload?.handle
            }
          })
        } catch (error) {
          this.setState({
            uploadProfilePicStatus: "Operation exceeded time limit.",
          })
        }
      } else {
        this.setState({
          uploadProfilePicStatus: "File cannot be uploaded to S3.",
        })
      }
      return null
    } else {
      this.setState({
        uploadProfilePicStatus: json?.error
      })
      throw new Error(json.error)
    }
  }

  async resubscribe () {
    this.setState({
      resubscribing: true
    })
    const data = {
      channelId: this.state.channelObj?.payload?.channel,
    }
    const result = await fetch(`${SERVER_API_PATH}/resubscribe`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data),
    }).then(res => res.json())
    if (result.ok) {
      this.setState({
        unsubscribe: false,
        resubscribing: false
      })
    } else {
      this.setState({
        unsubscribe: true,
        resubscribing: false
      })
    }
  }

  uploadProfilePicDisplay() {
    const { uploadProfilePicStatus, filename } = this.state
    if (uploadProfilePicStatus === "loading") {
      return <Styles.ProfilePicStatusContainer>
        <FontAwesomeIcon
          icon={faCircleNotch}
          spin
        />
        <span style={{ paddingLeft: "4px" }}>Loading...</span>
      </Styles.ProfilePicStatusContainer>
    }

    if (uploadProfilePicStatus !== "loading" && uploadProfilePicStatus !== "success" && uploadProfilePicStatus?.length ) {
      return <Styles.ProfilePicStatusContainer>
        <FontAwesomeIcon
          icon={faExclamationCircle}
        />
        <span style={{ paddingLeft: "4px" }}>{uploadProfilePicStatus}</span>
      </Styles.ProfilePicStatusContainer>
    }

    if (filename && uploadProfilePicStatus === "success") {
      return <Styles.Filename>{filename}</Styles.Filename>
    }
  }

  settingsView() {
    const { info, loadingProfile, profileData, originalProfileData, updateSuccess, updateErrorMessage } = this.state
    return (
      <div>
        <div style={{ display: "flex", justifyContent: "center", padding: "32px 8px 32px 8px" }}>
          <div style={{ width: "30%", paddingRight: "48px", color: "#707070", boxSizing: "border-box" }}>
            <div style={{ fontWeight: "bold", fontSize: "20px" }}>WhatsApp Info</div>
          </div>
          <div style={{ width: "70%" }}>
            <table>
              {info?.formattedPhoneNumber &&
                <tr style={{ fontSize: "0.8rem" }}>
                  <td style={{ width: "120px", paddingBottom: "8px", color: "#707070" }}>WhatsApp Number</td>
                  <td style={{ fontWeight: "bold", paddingBottom: "8px", color: "#626262" }}>
                    <div>
                      {info?.formattedPhoneNumber}
                      {info?.phoneNumber &&
                        <Styles.ExternalLink
                          className="link"
                          href={`https://wa.me/${info?.phoneNumber}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon
                            className="icon"
                            icon={faExternalLinkAlt}
                          />
                        </Styles.ExternalLink>
                      }
                    </div>
                  </td>
                </tr>
              }
              {info?.phoneDisplayName &&
                <tr style={{ fontSize: "0.8rem" }}>
                  <td style={{ width: "120px", paddingBottom: "8px", color: "#707070" }}>Display Name</td>
                  <td style={{ fontWeight: "bold", paddingBottom: "8px", color: "#626262" }}>{info?.phoneDisplayName}</td>
                </tr>
              }
              {info?.wabaId &&
                <tr style={{ fontSize: "0.8rem" }}>
                  <td style={{ width: "120px", paddingBottom: "8px", color: "#707070" }}>WABA ID</td>
                  <td style={{ fontWeight: "bold", paddingBottom: "8px", color: "#626262" }}>{info?.wabaId}</td>
                </tr>
              }
              {info?.phoneNumberId &&
                <tr style={{ fontSize: "0.8rem" }}>
                  <td style={{ width: "120px", paddingBottom: "8px", color: "#707070" }}>Phone Number ID</td>
                  <td style={{ fontWeight: "bold", paddingBottom: "8px", color: "#626262" }}>{info?.phoneNumberId}</td>
                </tr>
              }
            </table>
          </div>
        </div>
        <div style={{ height: "1px", background: "#e8e7e8" }} />
        <div style={{ display: "flex", justifyContent: "center", padding: "32px 8px 32px 8px" }}>
          <div style={{ width: "30%", paddingRight: "48px", color: "#707070", boxSizing: "border-box" }}>
            <div style={{ fontWeight: "bold", fontSize: "20px" }}>Business Profile Settings</div>
            <div style={{ lineHeight: "1.4", padding: "8px 0", fontSize: "0.9rem" }}>In the WhatsApp app, click on Chat with business account, then click on the name at the top to see the complete contact information including Business Details.</div>
          </div>
          <div style={{ width: "70%" }}>
            {loadingProfile ?
              <Styles.LoadingContainer>
                <FontAwesomeIcon icon={faCircleNotch} spin />
              </Styles.LoadingContainer>
              :
              <>
                <div style={{ display: "flex", alignItems: "center", paddingBottom: "16px" }}>
                  {profileData?.profile_picture_url ?
                    <img
                      src={profileData.profile_picture_url}
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "40px",
                        border: "1px solid #e8e7e8"
                      }}
                    />
                    :
                    <Styles.MockImage>
                      <FontAwesomeIcon
                        icon={faCamera}
                      />
                    </Styles.MockImage>
                  }
                  <div style={{ display: "flex", alignItems: "center", paddingLeft: "8px" }}>
                    <Styles.UploadLabel htmlFor="upload-file">
                      <Styles.UploadFileButton>
                        {profileData.profile_picture_url ? "Edit Profile Picture" : "Add Profile Picture"}
                      </Styles.UploadFileButton>
                      <Styles.FileInput
                        type="file"
                        id="upload-file"
                        onChange={(e) => {
                          this.resumableUpload(e.target.files)
                        }}
                      />
                    </Styles.UploadLabel>
                    {this.uploadProfilePicDisplay()}
                  </div>
                </div>

                <div style={{ paddingBottom: "8px" }}>
                  <div style={{ fontSize: "0.7rem", color: "#a5a5a6" }}>Address</div>
                  <Styles.TextInput
                    label="Address"
                    placeholder="Address"
                    value={profileData?.address}
                    onChange={(e) => {
                      const clone = _.cloneDeep(profileData)
                      clone.address = e.target.value
                      this.setState({
                        profileData: clone
                      })
                    }}
                  />
                </div>

                <div style={{ paddingBottom: "8px" }}>
                  <div style={{ fontSize: "0.7rem", color: "#a5a5a6" }}>Description</div>
                  <Styles.TextInput
                    label="Description"
                    placeholder="Description"
                    value={profileData?.description}
                    onChange={(e) => {
                      const clone = _.cloneDeep(profileData)
                      clone.description = e.target.value
                      this.setState({
                        profileData: clone
                      })
                    }}
                  />
                </div>

                <div style={{ paddingBottom: "8px" }}>
                  <div style={{ fontSize: "0.7rem", color: "#a5a5a6" }}>Email</div>
                  <Styles.TextInput
                    label="Email"
                    placeholder="Email"
                    value={profileData?.email}
                    onChange={(e) => {
                      const clone = _.cloneDeep(profileData)
                      clone.email = e.target.value
                      this.setState({
                        profileData: clone
                      })
                    }}
                  />
                </div>

                <div style={{ paddingBottom: "7px" }}>
                  <div style={{ fontSize: "0.7rem", color: "#a5a5a6", paddingBottom: "2px" }}>Business Category (Service Type)</div>
                  <Select
                    options={categoryOptions}
                    value={categoryOptions.find(o => o.value === profileData?.vertical)}
                    onChange={(value) => {
                      const clone = _.cloneDeep(profileData)
                      clone.vertical = value.value
                      this.setState({
                        profileData: clone
                      })
                    }}
                  />
                </div>

                <div style={{ paddingBottom: "8px" }}>
                  <div style={{ fontSize: "0.7rem", color: "#a5a5a6" }}>Website 1</div>
                  <Styles.TextInput
                    label="Website 1"
                    placeholder="Website 1"
                    value={profileData?.websites?.length ? profileData?.websites[0] : ""}
                    onChange={(e) => {
                      const clone = _.cloneDeep(profileData)
                      if (_.isArray(clone.websites)) {
                        clone.websites[0] = e.target.value
                      } else {
                        clone.websites = [e.target.value]
                      }
            
                      this.setState({
                        profileData: clone
                      })
                    }}
                  />
                </div>

                <div style={{ paddingBottom: "16px" }}>
                  <div style={{ fontSize: "0.7rem", color: "#a5a5a6" }}>Website 2</div>
                  <Styles.TextInput
                    label="Website 2"
                    placeholder="Website 2"
                    disabled={!profileData?.websites?.length}
                    value={profileData?.websites?.length ? profileData?.websites[1] : ""}
                    onChange={(e) => {
                      const clone = _.cloneDeep(profileData)
                      clone.websites[1] = e.target.value
                      this.setState({
                        profileData: clone
                      })
                    }}
                  />
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <NewBoxButton
                    style={{ fontSize: "1rem", minWidth: "108px" }}
                    primary
                    text="Update Profile"
                    disabled={_.isEqual(profileData, originalProfileData)}
                    onClick={() => {
                      this.updateWhatsappProfile(this.state.profileData)
                    }}
                  />
                  {updateSuccess &&
                    <Styles.ProfileStatus success>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="success-icon"
                      />
                      <span>Profile updated.</span>
                    </Styles.ProfileStatus>
                  }
                  {updateErrorMessage &&
                    <Styles.ProfileStatus>
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="error-icon"
                      />
                      <span>{updateErrorMessage}</span>
                    </Styles.ProfileStatus>
                  }
                </div>
              </>
            }
          </div>
        </div>
      </div>
    )
  }

  renderInfo() {
    const { info, channelObj, unsubscribe } = this.state
    if (this.state.loading) {
      return (
        <Styles.LoadingContainer>
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </Styles.LoadingContainer>
      )
    } else if (_.isEmpty(info)) {
      return (
        <SetUp
          payload={channelObj?.payload}
          signedContext={channelObj?.signedContext}
          onGetDataFromDb={() => {
            this.getDataFromDb()
          }}
        />
      )
    } else if (info) {
      if (unsubscribe) {
        return (
          <Styles.Overlay>
            <div className="overlay-content">
              <div className="unsubscribe-text">This channel has been unsubscribed, and is not functional.</div>
              <div className="unsubscribe-text">No further charges will be incurred.</div>
              <NewBoxButton
                primary
                className="resubscribe-button"
                loading={this.state.resubscribing}
                text="Subscribe"
                onClick={() => {
                  this.resubscribe()
                }}
              />
            </div>
          </Styles.Overlay>
        )
      }
      return (
        <Tab
          newTheme
          tabs={[{
            id: "settings",
            text: "Settings",
            content: this.settingsView(),
            disabled: false,
          }, {
            id: "messageTemplate",
            text: "Message Template",
            content: (
              <MessageTemplate 
                info={this.state.info}
                payload={channelObj?.payload}
                signedContext={channelObj?.signedContext}
              />
            ),
            disabled: false,
          }, {
            id: "advancedAccess",
            text: "Advanced Access",
            content: (
              <AccessTokenManager
                info={this.state.info}
                payload={channelObj?.payload}
                signedContext={channelObj?.signedContext}
              />
            )
          }]}
        />
      )
    }
  }

  render() {
    return (
      <div style={{ height: "100%" }}>
        <Helmet>
          <title>WhatsApp Cloud API Integration</title>
        </Helmet>
        <Styles.BodyContainer>
          {this.renderInfo()}
        </Styles.BodyContainer>
      </div>
    )
  }
}

export default Home
