import React, { useState, useEffect } from "react"
import styled from "styled-components"

import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"
import WhatsappMessageTemplateSampleHeaderContent from "./WhatsappMessageTemplateSampleHeaderContent"
import WhatsappMessageTemplateSampleBodyContent from "./WhatsappMessageTemplateSampleBodyContent"
import WhatsappMessageTemplateSampleButtonContent from "./WhatsappMessageTemplateSampleButtonContent"

const ModalContainer = styled.form`
  width: 50vw;
  height: 60vh;
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.gray6};
  box-shadow: ${props => props.theme.modalBoxShadow};
  border-radius: 4px;
  overflow: hidden;
  background-color: #ffffff;
`

const TopBar = styled.div`
  display: flex;
  padding: 16px;
`

const TopBarTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  color: #000;
`

const Content = styled.div`
  padding: 16px;
  overflow-y: scroll;
`

const Description = styled.div`
  margin-bottom: 8px;
  font-size: 0.8rem;
`

const BoldText = styled.div`
  margin-top: 16px;
  font-weight: bold;
`

const BottomBar = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  margin-top: auto;

  & > .bottom-bar-cancel-button {
    margin-right: 16px;
  }
`

const WhatsappMessageTemplateSampleContent = ({
  modalContent,
  onClose,
  index,
  change,
  values,
  setFile,
  selectedMessageTemplate,
  info,
  ...props
}) => {
  useEffect(() => {
    const close = (e) => {
      if (e.key === "Escape") {
        onClose()
      }
    }
    document.addEventListener("keydown", close)
    return () => document.removeEventListener("keydown", close)
  })

  const [selectedMT, setSelectedMT] = useState(selectedMessageTemplate || {})

  const renderModalContent = () => {
    switch (modalContent) {
      case "header":
        return (
          <WhatsappMessageTemplateSampleHeaderContent
            index={index}
            onClose={onClose}
            values={values}
            change={change}
            setFile={setFile}
            selectedMT={selectedMT}
            onChange={value => setSelectedMT(value)}
            info={info}
            {...props}
          />
        )
      case "body":
        return (
          <WhatsappMessageTemplateSampleBodyContent
            index={index}
            values={values}
            selectedMT={selectedMT}
            onChange={value => setSelectedMT(value)}
            {...props}
          />
        )
      case "button":
        return (
          <WhatsappMessageTemplateSampleButtonContent
            index={index}
            values={values}
            selectedMT={selectedMT}
            onChange={value => setSelectedMT(value)}
            {...props}
          />
        )
      default:
        return null
    }
  }

  return (
    <ModalContainer>
      <TopBar>
        <TopBarTitle>Add Sample content</TopBarTitle>
      </TopBar>
      <Content>
        <Description>
          To help us understand what kind of message you want to send,
          you have the option to provide specific content examples for your template.
          You can add a sample template for one or all languages you are submitting.
          <br /><br />
          Make sure not to include any actual user or customer information, and provide only sample content in your examples.
          {modalContent === "button" ? (
            <BoldText>Please DO NOT enter the full URL in below field</BoldText>
          ) : null}
        </Description>
        {renderModalContent()}
      </Content>
      <BottomBar>
        <NewBoxButton
          text="Cancel"
          type="button"
          className="bottom-bar-cancel-button"
          onClick={() => onClose()}
        />
        <NewBoxButton
          primary
          text="Add Sample"
          type="button"
          className="bottom-bar-confirm-button"
          onClick={() => {
            change(`messageTemplates[${index}]`, selectedMT)
            onClose()
          }}
        />
      </BottomBar>
    </ModalContainer>
  )
}

export default WhatsappMessageTemplateSampleContent
